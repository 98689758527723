import { createReducer } from 'reduxsauce'
import { sortBy } from 'lodash'
import { convertRawBundle, convertRawSalesProduct, convertRawSubscription, convertSubscriptionSalesProduct } from 'types/billingAgreement'
// Local deps
import { INITIAL_STATE } from './initialState'
import { BillingAgreementTypes } from './actions'
import { KeyTypes } from 'modules/keys/actions'
import { GrantsTypes } from 'modules/grants/actions'
import { makeUnique, mapById, removeById } from 'utils/list'
import { convertRawLicenseKey } from 'types/licenseKeys'
import { convertRawPlanGrant } from 'types/grants'
import { convertRawLog } from 'types/logs'
import { UsersTypes } from 'modules/users/actions'

export const cancelStripeBillingAgreementSuccess = state =>
  state.merge({
    subscribed: false,
    subscriptionType: null,
    cancelSubscriptionId: null,
    cancelSubscriptionCompanyId: null,
  })

// Execute Stripe billingAgreement
export const executeStripeBillingAgreementLoading = state =>
  state.merge({
    executeStripeBillingAgreementIsLoading: true,
    executeStripeBillingAgreementErrorMessage: '',
  })

export const executeStripeBillingAgreementSuccess = (state, { subscriptionType }) =>
  state.merge({
    subscribed: true,
    subscriptionType,
    executeStripeBillingAgreementIsLoading: false,
    executeStripeBillingAgreementErrorMessage: null,
  })

export const executeStripeBillingAgreementFailure = (state, { errorMessage }) =>
  state.merge({
    executeStripeBillingAgreementIsLoading: false,
    executeStripeBillingAgreementErrorMessage: errorMessage,
  })

// Execute PayPal billingAgreement
export const executePayPalBillingAgreementLoading = state =>
  state.merge({
    executePayPalBillingAgreementIsLoading: true,
    executePayPalBillingAgreementErrorMessage: '',
  })

export const executePayPalBillingAgreementSuccess = (state, { subscriptionType }) =>
  state.merge({
    subscribed: true,
    subscriptionType,
    executePayPalBillingAgreementIsLoading: false,
    executePayPalBillingAgreementErrorMessage: null,
  })

export const executePayPalBillingAgreementFailure = (state, { errorMessage }) =>
  state.merge({
    executePayPalBillingAgreementIsLoading: false,
    executePayPalBillingAgreementErrorMessage: errorMessage,
  })

// Create PayPal billingAgreement
export const createPayPalBillingAgreementLoading = state =>
  state.merge({
    createPayPalBillingAgreementIsLoading: true,
    createPayPalBillingAgreementErrorMessage: '',
  })

export const createPayPalBillingAgreementSuccess = state =>
  state.merge({
    createPayPalBillingAgreementIsLoading: false,
    createPayPalBillingAgreementErrorMessage: null,
  })

export const createPayPalBillingAgreementFailure = (state, { errorMessage }) =>
  state.merge({
    createPayPalBillingAgreementIsLoading: false,
    createPayPalBillingAgreementErrorMessage: errorMessage,
  })

// Open Stripe form dialog window
export const openStripeFormDialog = (state, { salesProductToken, durationToken, edit, dialogState }) =>
  state.merge({
    stripePlanToken: salesProductToken,
    durationToken: durationToken,
    stripePlanState: edit,
    stripeDialogState: dialogState,
    isStripeFormDialogOpened: true,
  })

// Close Stripe form dialog window
export const closeStripeFormDialog = state =>
  state.merge({
    stripePlanToken: null,
    stripePlanState: false,
    stripeDialogState: null,
    isStripeFormDialogOpened: false,
  })

// Open Stripe cancel dialog window
export const openStripeCancelDialog = (state, { subscriptionId, companyId }) =>
  state.merge({
    isStripeCancelDialogOpened: true,
    cancelSubscriptionId: subscriptionId,
    cancelSubscriptionCompanyId: companyId,
  })

// Close Stripe cancel dialog window
export const closeStripeCancelDialog = state =>
  state.merge({
    isStripeCancelDialogOpened: false,
    cancelSubscriptionId: null,
    cancelSubscriptionCompanyId: null,
  })

// Set paypal token
export const setPaypalToken = (state, { paypalSuccess, paymentToken }) => {
  return state.merge({
    paypalSuccess,
    paymentToken,
  })
}

export const activateOfflineSubscriptionLoading = (state, { companyId }) => {
  return state.merge({
    activateOfflineSubscriptionIsLoading: {
      ...state.get('activateOfflineSubscriptionIsLoading'),
      [companyId]: true,
    },
  })
}
export const activateOfflineSubscriptionSuccess = (state, { companyId, subscription, salesProducts }) => {
  return state.merge({
    activateOfflineSubscriptionIsLoading: {
      ...state.get('activateOfflineSubscriptionIsLoading'),
      [companyId]: false,
    },
    allSubscriptions: [...state.get('allSubscriptions'), convertRawSubscription(subscription, salesProducts)],
  })
}
export const activateOfflineSubscriptionFailure = (state, { companyId }) => {
  return state.merge({
    activateOfflineSubscriptionIsLoading: {
      ...state.get('activateOfflineSubscriptionIsLoading'),
      [companyId]: false,
    },
  })
}

export const deactivateSubscriptionsLoading = (state, { companyId }) => {
  return state.merge({
    cancelSubscriptionIsLoading: {
      ...state.get('cancelSubscriptionIsLoading'),
      [companyId]: true,
    },
  })
}
export const deactivateSubscriptionsSuccess = (state, { companyId }) => {
  return state.merge({
    cancelSubscriptionIsLoading: {
      ...state.get('cancelSubscriptionIsLoading'),
      [companyId]: false,
    },
  })
}
export const deactivateSubscriptionsFailure = (state, { companyId }) => {
  return state.merge({
    cancelSubscriptionIsLoading: {
      ...state.get('cancelSubscriptionIsLoading'),
      [companyId]: false,
    },
  })
}

export const deactivateSubscriptionLoading = (state, { subscriptionId }) => {
  return state.merge({
    deactivateSubscriptionState: {
      ...state.get('deactivateSubscriptionState'),
      [subscriptionId]: 'loading',
    },
  })
}
export const deactivateSubscriptionSuccess = (state, { subscriptionId }) => {
  return state.merge({
    deactivateSubscriptionState: {
      ...state.get('deactivateSubscriptionState'),
      [subscriptionId]: 'success',
    },
  })
}
export const deactivateSubscriptionFailure = (state, { subscriptionId, failureMessage }) => {
  return state.merge({
    deactivateSubscriptionState: {
      ...state.get('deactivateSubscriptionState'),
      [subscriptionId]: 'failed',
    },
    deactivateSubscriptionFailureMessage: {
      ...state.get('deactivateSubscriptionFailureMessage'),
      [subscriptionId]: failureMessage,
    },
  })
}

// Create PayPal billingAgreement
export const getSalesProductsLoading = state =>
  state.merge({
    getSalesProductsIsLoading: true,
    getSalesProductsErrorMessage: '',
  })

export const getSalesProductsSuccess = (state, { salesProducts, grantTypes, salesBundles }) => {
  return state.merge({
    salesProducts: sortBy(salesProducts.map(plan => convertRawSalesProduct(plan, grantTypes)), ['name']),
    durationsMap: salesProducts.reduce((all, salesProduct) => {
      return {
        ...all,
        ...(salesProduct.durations || []).reduce((all, duration) => ({ ...all, [duration.id]: salesProduct.id }), {}),
      }
    }, {}),
    durationTypes: makeUnique(salesProducts.reduce((all, salesProduct) => {
      return [
        ...all,
        ...(salesProduct.durations || []).reduce((all, duration) => [...all, duration.duration_type], []),
      ]
    }, [])),
    salesProductsTypes: makeUnique(salesProducts.reduce((all, salesProduct) => [...all, salesProduct.sales_product_type], [])),
    salesBundles: salesBundles.map(bundle => convertRawBundle(bundle, grantTypes)),
    grantTypes: grantTypes,
    getSalesProductsIsLoading: false,
    getSalesProductsErrorMessage: null,
  })
}

export const getSalesProductsFailure = (state, { errorMessage }) =>
  state.merge({
    getSalesProductsIsLoading: false,
    getSalesProductsErrorMessage: errorMessage,
  })

export const getSubscriptionInvoicesLoading = (state, { subscriptionId }) => state.merge({
  getSubscriptionInvoicesIsLoading: {
    ...state.get('getSubscriptionInvoicesIsLoading'),
    [subscriptionId]: true,
  },
})
export const getSubscriptionInvoicesSuccess = (state, { subscriptionId, invoices }) => state.merge({
  invoices: {
    ...state.get('invoices'),
    [subscriptionId]: invoices.map(i => {
      const invoiceCharge = i.charge
      return invoiceCharge ? ({
        ...i,
        refunds: invoiceCharge ? invoiceCharge.refunds || [] : [],
      }) : i
    }),
  },
  getSubscriptionInvoicesIsLoading: {
    ...state.get('getSubscriptionInvoicesIsLoading'),
    [subscriptionId]: false,
  },
})
export const getSubscriptionInvoicesFailure = (state, { subscriptionId }) => state.merge({
  getSubscriptionInvoicesIsLoading: {
    ...state.get('getSubscriptionInvoicesIsLoading'),
    [subscriptionId]: false,
  },
})

export const getSubscriptionGrantsLoading = (state, { subscriptionId }) => state.merge({
  getSubscriptionGrantsIsLoading: {
    ...state.get('getSubscriptionGrantsIsLoading'),
    [subscriptionId]: true,
  },
})
export const getSubscriptionGrantsSuccess = (state, { subscriptionId, grants }) => state.merge({
  grants: {
    ...state.get('grants'),
    [subscriptionId]: grants,
  },
  getSubscriptionGrantsIsLoading: {
    ...state.get('getSubscriptionGrantsIsLoading'),
    [subscriptionId]: false,
  },
})
export const getSubscriptionGrantsFailure = (state, { subscriptionId }) => state.merge({
  getSubscriptionGrantsIsLoading: {
    ...state.get('getSubscriptionGrantsIsLoading'),
    [subscriptionId]: false,
  },
})

export const getPlanGrantsLoading = (state, { planToken }) => state.merge({
  getPlanGrantsIsLoading: {
    ...state.get('getPlanGrantsIsLoading'),
    [planToken]: true,
  },
})
export const getPlanGrantsSuccess = (state, { planToken, grants }) => state.merge({
  planGrants: {
    ...state.get('planGrants'),
    [planToken]: grants.map(convertRawPlanGrant),
  },
  getPlanGrantsIsLoading: {
    ...state.get('getPlanGrantsIsLoading'),
    [planToken]: false,
  },
})
export const getPlanGrantsFailure = (state, { planToken }) => state.merge({
  getPlanGrantsIsLoading: {
    ...state.get('getPlanGrantsIsLoading'),
    [planToken]: false,
  },
})

export const createPlanGrantLoading = (state, { productId }) => state.merge({
  createPlanGrantIsLoading: true,
})
export const createPlanGrantSuccess = (state, { productId, grant }) => {
  const grantTypes = state.get('grantTypes')
  const salesProducts = state.get('salesProducts')
  return state.merge({
    salesProducts: mapById(productId, salesProducts, salesProduct => ({
      ...salesProduct,
      grantTypes: [
        ...salesProduct.grantTypes,
        convertRawPlanGrant(grant, grantTypes),
      ],
    })),
    createPlanGrantIsLoading: false,
  })
}
export const createPlanGrantFailure = (state, { productId }) => state.merge({
  createPlanGrantIsLoading: false,
})

export const deletePlanGrantLoading = (state, { productId }) => state.merge({
  deletePlanGrantIsLoading: true,
})
export const deletePlanGrantSuccess = (state, { productId, grantId }) => {
  const salesProducts = state.get('salesProducts')
  return state.merge({
    salesProducts: mapById(productId, salesProducts, salesProduct => ({
      ...salesProduct,
      grantTypes: removeById(grantId, salesProduct.grantTypes || []),
    })),
    deletePlanGrantIsLoading: false,
  })
}
export const deletePlanGrantFailure = (state, { productId }) => state.merge({
  deletePlanGrantIsLoading: false,
})

export const createGrantSuccess = (state, { grant, subscriptionId }) => state.merge({
  grants: {
    ...state.get('grants'),
    [subscriptionId]: [
      grant,
      ...(state.get('grants')[subscriptionId] || []),
    ],
  },
})

export const getSubscriptionLicenseKeysLoading = (state, { subscriptionId }) => state.merge({
  getSubscriptionLicenseKeysIsLoading: {
    ...state.get('getSubscriptionLicenseKeysIsLoading'),
    [subscriptionId]: true,
  },
})
export const getSubscriptionLicenseKeysSuccess = (state, { subscriptionId, licenseKeys }) => state.merge({
  licenseKeys: {
    ...state.get('licenseKeys'),
    [subscriptionId]: licenseKeys.map(convertRawLicenseKey),
  },
  getSubscriptionLicenseKeysIsLoading: {
    ...state.get('getSubscriptionLicenseKeysIsLoading'),
    [subscriptionId]: false,
  },
})
export const getSubscriptionLicenseKeysFailure = (state, { subscriptionId }) => state.merge({
  licenseKeys: {
    ...state.get('licenseKeys'),
    [subscriptionId]: [],
  },
  getSubscriptionLicenseKeysIsLoading: {
    ...state.get('getSubscriptionLicenseKeysIsLoading'),
    [subscriptionId]: false,
  },
})

// Sets the loading state for fetching license key added events of a subscription.
export const getLicenseKeyAddedEventsLoading = (state, { subscriptionId }) => state.merge({
  getLicenseKeyAddedEventsIsLoading: {
    ...state.get('getLicenseKeyAddedEventsIsLoading'),
    [subscriptionId]: true,
  },
})
// Updates the state with the successfully fetched license key added events.
export const getLicenseKeyAddedEventsSuccess = (state, { subscriptionId, licenseKeyAddedEvents }) => state.merge({
  licenseKeyAddedEvents: {
    ...state.get('licenseKeyAddedEvents'),
    [subscriptionId]: licenseKeyAddedEvents,
  },
  getLicenseKeyAddedEventsIsLoading: {
    ...state.get('getLicenseKeyAddedEventsIsLoading'),
    [subscriptionId]: false,
  },
})
// Handles failure when fetching license key added events by setting an empty list and updating the loading state.
export const getLicenseKeyAddedEventsFailure = (state, { subscriptionId }) => state.merge({
  licenseKeyAddedEvents: {
    ...state.get('licenseKeyAddedEvents'),
    [subscriptionId]: [],
  },
  getLicenseKeyAddedEventsIsLoading: {
    ...state.get('getLicenseKeyAddedEventsIsLoading'),
    [subscriptionId]: false,
  },
})

export const getLicenseKeysAttachmentsLoading = (state, { licenseKeysIds }) => state.merge({
  getLicenseKeyAttachmentsIsLoading: {
    ...state.get('getLicenseKeyAttachmentsIsLoading'),
    ...(licenseKeysIds.reduce((all, licenseKeysId) => ({ ...all, [licenseKeysId]: true }), {})),
  },
})
export const getLicenseKeysAttachmentsSuccess = (state, { licenseKeysIds, attachmentsByLicenseKey }) => state.merge({
  licenseKeyAttachments: {
    ...state.get('licenseKeyAttachments'),
    ...(licenseKeysIds.reduce((all, licenseKeysId) => ({ ...all, [licenseKeysId]: attachmentsByLicenseKey[licenseKeysId] }), {})),
  },
  getLicenseKeyAttachmentsIsLoading: {
    ...state.get('getLicenseKeyAttachmentsIsLoading'),
    ...(licenseKeysIds.reduce((all, licenseKeysId) => ({ ...all, [licenseKeysId]: false }), {})),
  },
})
export const getLicenseKeysAttachmentsFailure = (state, { licenseKeysIds }) => state.merge({
  licenseKeyAttachments: {
    ...state.get('licenseKeyAttachments'),
    ...(licenseKeysIds.reduce((all, licenseKeysId) => ({ ...all, [licenseKeysId]: [] }), {})),
  },
  getLicenseKeyAttachmentsIsLoading: {
    ...state.get('getLicenseKeyAttachmentsIsLoading'),
    ...(licenseKeysIds.reduce((all, licenseKeysId) => ({ ...all, [licenseKeysId]: false }), {})),
  },
})

export const stripeRefundLoading = (state, { subscriptionId, chargeId }) => state.merge({
  stripeRefundIsLoading: {
    ...state.get('stripeRefundIsLoading'),
    [chargeId]: true,
  },
})
export const stripeRefundSuccess = (state, { subscriptionId, chargeId, refund }) => {
  const invoices = state.get('invoices')
  return state.merge({
    invoices: {
      ...invoices,
      [subscriptionId]: (invoices[subscriptionId] || [])
        .map(i => i.charge === chargeId ? ({ ...i, refunds: [...i.refunds, refund] }) : i),
    },
    stripeRefundIsLoading: {
      ...state.get('stripeRefundIsLoading'),
      [chargeId]: false,
    },
  })
}
export const stripeRefundFailure = (state, { subscriptionId, chargeId }) => state.merge({
  stripeRefundIsLoading: {
    ...state.get('stripeRefundIsLoading'),
    [chargeId]: false,
  },
})

export const getSubscriptionCardLoading = (state, { subscriptionId }) => state.merge({
  getSubscriptionCardFailureMessage: {
    ...state.get('getSubscriptionCardFailureMessage'),
    [subscriptionId]: '',
  },
  getSubscriptionCardIsLoading: {
    ...state.get('getSubscriptionCardIsLoading'),
    [subscriptionId]: true,
  },
})
export const getSubscriptionCardSuccess = (state, { subscriptionId, card }) => state.merge({
  cards: {
    ...state.get('cards'),
    [subscriptionId]: card,
  },
  getSubscriptionCardIsLoading: {
    ...state.get('getSubscriptionCardIsLoading'),
    [subscriptionId]: false,
  },
})
export const getSubscriptionCardFailure = (state, { subscriptionId, message }) => state.merge({
  getSubscriptionCardIsLoading: {
    ...state.get('getSubscriptionCardIsLoading'),
    [subscriptionId]: false,
  },
  getSubscriptionCardFailureMessage: {
    ...state.get('getSubscriptionCardFailureMessage'),
    [subscriptionId]: message,
  },
})

export const updateSubscriptionCardLoading = (state, { subscriptionId }) => state.merge({
  updateSubscriptionCardIsLoading: {
    ...state.get('updateSubscriptionCardIsLoading'),
    [subscriptionId]: true,
  },
})
export const updateSubscriptionCardSuccess = (state, { subscriptionId }) => state.merge({
  updateSubscriptionCardIsLoading: {
    ...state.get('updateSubscriptionCardIsLoading'),
    [subscriptionId]: false,
  },
})
export const updateSubscriptionCardFailure = (state, { subscriptionId }) => state.merge({
  updateSubscriptionCardIsLoading: {
    ...state.get('updateSubscriptionCardIsLoading'),
    [subscriptionId]: false,
  },
})

export const editKeyLoading = (state, { subscriptionId }) => state.merge({})
export const editKeySuccess = (state, { subscriptionId, keyId, data, licenseKey }) => {
  const licenseKeys = state.get('licenseKeys')
  return state.merge({
    licenseKeys: {
      ...licenseKeys,
      [subscriptionId]: mapById(
        keyId,
        (licenseKeys[subscriptionId] || []),
        lk => data
          ? convertRawLicenseKey({ ...lk, ...data })
          : convertRawLicenseKey(licenseKey),
      ),
    },
  })
}
export const editKeyFailure = (state, { subscriptionId }) => state.merge({})

export const addKeyLoading = (state, { subscriptionId }) => state.merge({
  addKeyIsLoading: true,
})
export const addKeySuccess = (state, { companyId, subscriptionId, key }) => {
  const licenseKeys = state.get('licenseKeys')
  return state.merge({
    addKeyIsLoading: false,
    licenseKeys: {
      ...licenseKeys,
      [subscriptionId]: [
        key,
        ...(licenseKeys[subscriptionId] || []),
      ],
    },
  })
}
export const addKeyFailure = (state, { subscriptionId }) => state.merge({
  addKeyIsLoading: false,
})

export const transferSubscriptionsLoading = (state, { subscriptionId, oldCompanyId, newCompanyId, subscription }) => state.merge({
  transferSubscriptionIsLoading: true,
})
export const transferSubscriptionsSuccess = (state, { subscriptionId, oldCompanyId, newCompanyId, subscription }) => state.merge({
  transferSubscriptionIsLoading: false,
})
export const transferSubscriptionsFailure = (state, { subscriptionId, oldCompanyId, newCompanyId, subscription }) => state.merge({
  transferSubscriptionIsLoading: false,
})

export const getAllSubscriptionsLoading = state => {
  return state.merge({
    allSubscriptionsLoading: true,
  })
}

export const getAllSubscriptionsSuccess = (state, { subscriptions, pagination, salesProducts }) => {
  return state.merge({
    allSubscriptions: subscriptions.map(sub => convertRawSubscription(sub, salesProducts)),
    allSubscriptionsLoading: false,
    allSubscriptionsPagination: pagination,
  })
}

export const getAllSubscriptionsFailure = state => {
  return state.merge({
    allSubscriptionsLoading: false,
  })
}

export const getCheckoutSessionLoading = state => {
  return state.merge({})
}

export const getCheckoutSessionSuccess = (state, { sessionId, session }) => {
  return state.merge({
    lastCheckoutSession: session,
  })
}

export const getCheckoutSessionFailure = state => {
  return state.merge({})
}

export const setCheckoutSession = (state, { data }) => state.merge({
  checkoutSessionState: {
    ...state.get('checkoutSessionState'),
    ...data,
  },
})

export const setRetryPaymentState = (state, { data }) => state.merge({
  retryPaymentState: {
    ...state.get('retryPaymentState'),
    ...data,
  },
})

export const retryInvoiceLoading = (state, { invoiceId }) => state.merge({})
export const retryInvoiceSuccess = (state, { invoiceId, invoice }) => state.merge({})
export const retryInvoiceFailure = (state, { invoiceId }) => state.merge({})

export const retryGetSubscriptionLoading = (state, { subscriptionId }) => state.merge({})
export const retryGetSubscriptionSuccess = (state, { subscriptionId, subscription }) => state.merge({})
export const retryGetSubscriptionFailure = (state, { subscriptionId }) => state.merge({})

export const getSubscriptionDBLogsLoading = (state, { subscriptionId }) => state.merge({
  getSubscriptionDBLogsLoading: {
    ...state.get('getSubscriptionDBLogsLoading'),
    [subscriptionId]: true,
  },
})
export const getSubscriptionDBLogsFailure = (state, { subscriptionId }) => state.merge({
  getSubscriptionDBLogsLoading: {
    ...state.get('getSubscriptionDBLogsLoading'),
    [subscriptionId]: false,
  },
})
export const getSubscriptionDBLogsSuccess = (state, { subscriptionId, logs, pagination }) => state.merge({
  getSubscriptionDBLogsLoading: {
    ...state.get('getSubscriptionDBLogsLoading'),
    [subscriptionId]: false,
  },
  subscriptionLogs: {
    ...state.get('subscriptionLogs'),
    [subscriptionId]: logs.map(convertRawLog),
  },
  getSubscriptionDBLogsPagination: {
    ...state.get('getSubscriptionDBLogsPagination'),
    [subscriptionId]: pagination,
  },
})

export const getExtendEventsLoading = (state, { subscriptionId }) => state.merge({
  getSubscriptionExtendEventsFailureMessage: {
    ...state.get('getSubscriptionExtendEventsFailureMessage'),
    [subscriptionId]: '',
  },
  getSubscriptionExtendEventsIsLoading: {
    ...state.get('getSubscriptionExtendEventsIsLoading'),
    [subscriptionId]: true,
  },
})
export const getExtendEventsSuccess = (state, { subscriptionId, events }) => state.merge({
  extendEvents: {
    ...state.get('extendEvents'),
    [subscriptionId]: events,
  },
  getSubscriptionExtendEventsIsLoading: {
    ...state.get('getSubscriptionExtendEventsIsLoading'),
    [subscriptionId]: false,
  },
})
export const getExtendEventsFailure = (state, { subscriptionId, message }) => state.merge({
  getSubscriptionExtendEventsIsLoading: {
    ...state.get('getSubscriptionExtendEventsIsLoading'),
    [subscriptionId]: false,
  },
  getSubscriptionExtendEventsFailureMessage: {
    ...state.get('getSubscriptionExtendEventsFailureMessage'),
    [subscriptionId]: message,
  },
})

export const extendOfflineSubscriptionLoading = (state, { subscriptionId }) => state.merge({
  extendSubscriptionIsLoading: true,
})
export const extendOfflineSubscriptionSuccess = (state, { subscriptionId, events }) => state.merge({
  extendSubscriptionIsLoading: false,
})
export const extendOfflineSubscriptionFailure = (state, { subscriptionId, errorMessage }) => state.merge({
  extendSubscriptionIsLoading: false,
})

export const getCancelEventsLoading = (state, { subscriptionId }) => state.merge({
  getSubscriptionCancelEventsFailureMessage: {
    ...state.get('getSubscriptionCancelEventsFailureMessage'),
    [subscriptionId]: '',
  },
  getSubscriptionCancelEventsIsLoading: {
    ...state.get('getSubscriptionCancelEventsIsLoading'),
    [subscriptionId]: true,
  },
})
export const getCancelEventsSuccess = (state, { subscriptionId, events }) => state.merge({
  cancelEvents: {
    ...state.get('cancelEvents'),
    [subscriptionId]: events,
  },
  getSubscriptionCancelEventsIsLoading: {
    ...state.get('getSubscriptionCancelEventsIsLoading'),
    [subscriptionId]: false,
  },
})
export const getCancelEventsFailure = (state, { subscriptionId, message }) => state.merge({
  getSubscriptionCancelEventsIsLoading: {
    ...state.get('getSubscriptionCancelEventsIsLoading'),
    [subscriptionId]: false,
  },
  getSubscriptionCancelEventsFailureMessage: {
    ...state.get('getSubscriptionCancelEventsFailureMessage'),
    [subscriptionId]: message,
  },
})

export const updateSubscriptions = (state, { salesProducts }) => {
  return state.merge({
    allSubscriptions: state.get('allSubscriptions').map(sub => ({ ...sub, plan: convertSubscriptionSalesProduct(sub, salesProducts) })),
  })
}

export const updateSubscriptionSuccess = (state, { companyId, subscriptionId, subscription, salesProducts }) => {
  return state.merge({
    allSubscriptions: mapById(subscriptionId, state.get('allSubscriptions'), () => convertRawSubscription(subscription, salesProducts)),
    updateSubscriptionIsLoading: false,
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [BillingAgreementTypes.CANCEL_STRIPE_BILLING_AGREEMENT_SUCCESS]: cancelStripeBillingAgreementSuccess,

  [BillingAgreementTypes.EXECUTE_STRIPE_BILLING_AGREEMENT_LOADING]: executeStripeBillingAgreementLoading,
  [BillingAgreementTypes.EXECUTE_STRIPE_BILLING_AGREEMENT_SUCCESS]: executeStripeBillingAgreementSuccess,
  [BillingAgreementTypes.EXECUTE_STRIPE_BILLING_AGREEMENT_FAILURE]: executeStripeBillingAgreementFailure,

  [BillingAgreementTypes.EXECUTE_PAYPAL_BILLING_AGREEMENT_LOADING]: executePayPalBillingAgreementLoading,
  [BillingAgreementTypes.EXECUTE_PAYPAL_BILLING_AGREEMENT_SUCCESS]: executePayPalBillingAgreementSuccess,
  [BillingAgreementTypes.EXECUTE_PAYPAL_BILLING_AGREEMENT_FAILURE]: executePayPalBillingAgreementFailure,

  [BillingAgreementTypes.CREATE_PAYPAL_BILLING_AGREEMENT_LOADING]: createPayPalBillingAgreementLoading,
  [BillingAgreementTypes.CREATE_PAYPAL_BILLING_AGREEMENT_SUCCESS]: createPayPalBillingAgreementSuccess,
  [BillingAgreementTypes.CREATE_PAYPAL_BILLING_AGREEMENT_FAILURE]: createPayPalBillingAgreementFailure,

  [BillingAgreementTypes.GET_SALES_PRODUCTS_LOADING]: getSalesProductsLoading,
  [BillingAgreementTypes.GET_SALES_PRODUCTS_SUCCESS]: getSalesProductsSuccess,
  [BillingAgreementTypes.GET_SALES_PRODUCTS_FAILURE]: getSalesProductsFailure,

  [BillingAgreementTypes.ACTIVATE_OFFLINE_SUBSCRIPTION_LOADING]: activateOfflineSubscriptionLoading,
  [BillingAgreementTypes.ACTIVATE_OFFLINE_SUBSCRIPTION_SUCCESS]: activateOfflineSubscriptionSuccess,
  [BillingAgreementTypes.ACTIVATE_OFFLINE_SUBSCRIPTION_FAILURE]: activateOfflineSubscriptionFailure,

  [BillingAgreementTypes.DEACTIVATE_SUBSCRIPTIONS_LOADING]: deactivateSubscriptionsLoading,
  [BillingAgreementTypes.DEACTIVATE_SUBSCRIPTIONS_SUCCESS]: deactivateSubscriptionsSuccess,
  [BillingAgreementTypes.DEACTIVATE_SUBSCRIPTIONS_FAILURE]: deactivateSubscriptionsFailure,

  [BillingAgreementTypes.DEACTIVATE_SUBSCRIPTION_LOADING]: deactivateSubscriptionLoading,
  [BillingAgreementTypes.DEACTIVATE_SUBSCRIPTION_SUCCESS]: deactivateSubscriptionSuccess,
  [BillingAgreementTypes.DEACTIVATE_SUBSCRIPTION_FAILURE]: deactivateSubscriptionFailure,

  [BillingAgreementTypes.OPEN_STRIPE_FORM_DIALOG]: openStripeFormDialog,
  [BillingAgreementTypes.OPEN_STRIPE_CANCEL_DIALOG]: openStripeCancelDialog,
  [BillingAgreementTypes.CLOSE_STRIPE_FORM_DIALOG]: closeStripeFormDialog,
  [BillingAgreementTypes.CLOSE_STRIPE_CANCEL_DIALOG]: closeStripeCancelDialog,
  [BillingAgreementTypes.SET_PAYPAL_TOKEN]: setPaypalToken,

  [BillingAgreementTypes.GET_SUBSCRIPTION_INVOICES_LOADING]: getSubscriptionInvoicesLoading,
  [BillingAgreementTypes.GET_SUBSCRIPTION_INVOICES_FAILURE]: getSubscriptionInvoicesFailure,
  [BillingAgreementTypes.GET_SUBSCRIPTION_INVOICES_SUCCESS]: getSubscriptionInvoicesSuccess,

  [BillingAgreementTypes.GET_SUBSCRIPTION_GRANTS_LOADING]: getSubscriptionGrantsLoading,
  [BillingAgreementTypes.GET_SUBSCRIPTION_GRANTS_FAILURE]: getSubscriptionGrantsFailure,
  [BillingAgreementTypes.GET_SUBSCRIPTION_GRANTS_SUCCESS]: getSubscriptionGrantsSuccess,

  [BillingAgreementTypes.GET_PLAN_GRANTS_LOADING]: getPlanGrantsLoading,
  [BillingAgreementTypes.GET_PLAN_GRANTS_FAILURE]: getPlanGrantsFailure,
  [BillingAgreementTypes.GET_PLAN_GRANTS_SUCCESS]: getPlanGrantsSuccess,

  [BillingAgreementTypes.CREATE_PLAN_GRANT_LOADING]: createPlanGrantLoading,
  [BillingAgreementTypes.CREATE_PLAN_GRANT_FAILURE]: createPlanGrantFailure,
  [BillingAgreementTypes.CREATE_PLAN_GRANT_SUCCESS]: createPlanGrantSuccess,

  [BillingAgreementTypes.DELETE_PLAN_GRANT_LOADING]: deletePlanGrantLoading,
  [BillingAgreementTypes.DELETE_PLAN_GRANT_FAILURE]: deletePlanGrantFailure,
  [BillingAgreementTypes.DELETE_PLAN_GRANT_SUCCESS]: deletePlanGrantSuccess,

  [BillingAgreementTypes.UPDATE_SUBSCRIPTIONS]: updateSubscriptions,
  [UsersTypes.UPDATE_SUBSCRIPTION_SUCCESS]: updateSubscriptionSuccess,

  [BillingAgreementTypes.GET_SUBSCRIPTION_LICENSE_KEYS_LOADING]: getSubscriptionLicenseKeysLoading,
  [BillingAgreementTypes.GET_SUBSCRIPTION_LICENSE_KEYS_FAILURE]: getSubscriptionLicenseKeysFailure,
  [BillingAgreementTypes.GET_SUBSCRIPTION_LICENSE_KEYS_SUCCESS]: getSubscriptionLicenseKeysSuccess,

  [BillingAgreementTypes.GET_LICENSE_KEYS_ATTACHMENTS_LOADING]: getLicenseKeysAttachmentsLoading,
  [BillingAgreementTypes.GET_LICENSE_KEYS_ATTACHMENTS_FAILURE]: getLicenseKeysAttachmentsFailure,
  [BillingAgreementTypes.GET_LICENSE_KEYS_ATTACHMENTS_SUCCESS]: getLicenseKeysAttachmentsSuccess,

  [BillingAgreementTypes.GET_LICENSE_KEY_ADDED_EVENTS_LOADING]: getLicenseKeyAddedEventsLoading,
  [BillingAgreementTypes.GET_LICENSE_KEY_ADDED_EVENTS_FAILURE]: getLicenseKeyAddedEventsFailure,
  [BillingAgreementTypes.GET_LICENSE_KEY_ADDED_EVENTS_SUCCESS]: getLicenseKeyAddedEventsSuccess,

  [BillingAgreementTypes.GET_SUBSCRIPTION_CARD_LOADING]: getSubscriptionCardLoading,
  [BillingAgreementTypes.GET_SUBSCRIPTION_CARD_FAILURE]: getSubscriptionCardFailure,
  [BillingAgreementTypes.GET_SUBSCRIPTION_CARD_SUCCESS]: getSubscriptionCardSuccess,

  [BillingAgreementTypes.UPDATE_SUBSCRIPTION_CARD_LOADING]: updateSubscriptionCardLoading,
  [BillingAgreementTypes.UPDATE_SUBSCRIPTION_CARD_FAILURE]: updateSubscriptionCardFailure,
  [BillingAgreementTypes.UPDATE_SUBSCRIPTION_CARD_SUCCESS]: updateSubscriptionCardSuccess,

  [BillingAgreementTypes.STRIPE_REFUND_LOADING]: stripeRefundLoading,
  [BillingAgreementTypes.STRIPE_REFUND_FAILURE]: stripeRefundFailure,
  [BillingAgreementTypes.STRIPE_REFUND_SUCCESS]: stripeRefundSuccess,

  [BillingAgreementTypes.TRANSFER_SUBSCRIPTIONS_LOADING]: transferSubscriptionsLoading,
  [BillingAgreementTypes.TRANSFER_SUBSCRIPTIONS_FAILURE]: transferSubscriptionsFailure,
  [BillingAgreementTypes.TRANSFER_SUBSCRIPTIONS_SUCCESS]: transferSubscriptionsSuccess,

  [BillingAgreementTypes.GET_ALL_SUBSCRIPTIONS_LOADING]: getAllSubscriptionsLoading,
  [BillingAgreementTypes.GET_ALL_SUBSCRIPTIONS_FAILURE]: getAllSubscriptionsFailure,
  [BillingAgreementTypes.GET_ALL_SUBSCRIPTIONS_SUCCESS]: getAllSubscriptionsSuccess,

  [BillingAgreementTypes.GET_CHECKOUT_SESSION_LOADING]: getCheckoutSessionLoading,
  [BillingAgreementTypes.GET_CHECKOUT_SESSION_FAILURE]: getCheckoutSessionFailure,
  [BillingAgreementTypes.GET_CHECKOUT_SESSION_SUCCESS]: getCheckoutSessionSuccess,

  [BillingAgreementTypes.SET_CHECKOUT_SESSION]: setCheckoutSession,
  [BillingAgreementTypes.SET_RETRY_PAYMENT_STATE]: setRetryPaymentState,

  [BillingAgreementTypes.RETRY_INVOICE_LOADING]: retryInvoiceLoading,
  [BillingAgreementTypes.RETRY_INVOICE_FAILURE]: retryInvoiceFailure,
  [BillingAgreementTypes.RETRY_INVOICE_SUCCESS]: retryInvoiceSuccess,

  [BillingAgreementTypes.RETRY_GET_SUBSCRIPTION_LOADING]: retryGetSubscriptionLoading,
  [BillingAgreementTypes.RETRY_GET_SUBSCRIPTION_FAILURE]: retryGetSubscriptionFailure,
  [BillingAgreementTypes.RETRY_GET_SUBSCRIPTION_SUCCESS]: retryGetSubscriptionSuccess,

  [BillingAgreementTypes.GET_SUBSCRIPTION_DB_LOGS_LOADING]: getSubscriptionDBLogsLoading,
  [BillingAgreementTypes.GET_SUBSCRIPTION_DB_LOGS_FAILURE]: getSubscriptionDBLogsFailure,
  [BillingAgreementTypes.GET_SUBSCRIPTION_DB_LOGS_SUCCESS]: getSubscriptionDBLogsSuccess,

  [BillingAgreementTypes.GET_EXTEND_EVENTS_LOADING]: getExtendEventsLoading,
  [BillingAgreementTypes.GET_EXTEND_EVENTS_FAILURE]: getExtendEventsFailure,
  [BillingAgreementTypes.GET_EXTEND_EVENTS_SUCCESS]: getExtendEventsSuccess,

  [BillingAgreementTypes.GET_CANCEL_EVENTS_LOADING]: getCancelEventsLoading,
  [BillingAgreementTypes.GET_CANCEL_EVENTS_FAILURE]: getCancelEventsFailure,
  [BillingAgreementTypes.GET_CANCEL_EVENTS_SUCCESS]: getCancelEventsSuccess,

  [BillingAgreementTypes.EXTEND_OFFLINE_SUBSCRIPTION_LOADING]: extendOfflineSubscriptionLoading,
  [BillingAgreementTypes.EXTEND_OFFLINE_SUBSCRIPTION_FAILURE]: extendOfflineSubscriptionFailure,
  [BillingAgreementTypes.EXTEND_OFFLINE_SUBSCRIPTION_SUCCESS]: extendOfflineSubscriptionSuccess,

  [KeyTypes.EDIT_KEY_LOADING]: editKeyLoading,
  [KeyTypes.EDIT_KEY_FAILURE]: editKeyFailure,
  [KeyTypes.EDIT_KEY_SUCCESS]: editKeySuccess,

  [KeyTypes.ADD_KEY_LOADING]: addKeyLoading,
  [KeyTypes.ADD_KEY_FAILURE]: addKeyFailure,
  [KeyTypes.ADD_KEY_SUCCESS]: addKeySuccess,

  [GrantsTypes.CREATE_GRANT_SUCCESS]: createGrantSuccess,
  [KeyTypes.DOWNLOAD_ROVER_LICENSE_SUCCESS]: editKeySuccess,
})
