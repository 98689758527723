import moment from 'moment'
import config from 'config'
import { getBaseName } from 'utils/files'
import { convertRawAttachment } from 'types/attachments'

export const SystemTypeAction = {
  'ADD_SYSTEM_TYPE': 'ADD_SYSTEM_TYPE',
  'UPDATE_SYSTEM_TYPE': 'UPDATE_SYSTEM_TYPE',
  'DELETE_SYSTEM_TYPE': 'DELETE_SYSTEM_TYPE',
  'ADD_PROFILE': 'ADD_PROFILE',
  'UPDATE_PROFILE_NAME': 'UPDATE_PROFILE_NAME',
  'ADD_CONFIGURATION': 'ADD_CONFIGURATION',
  'SET_DEFAULT_PROFILE': 'SET_DEFAULT_PROFILE',
  'ADD_LINKS': 'ADD_LINKS',
  'UPDATE_LINK': 'UPDATE_LINK',
}

export const convertRawSystemType = (raw, oldRaw) => {
  const {
    created_at,
    updated_at,
    attachments = [],
  } = raw
  const oldAttachments = oldRaw ? oldRaw.attachments || [] : []
  const transformedAttachments = attachments.map(convertRawAttachment)
  // Filters new attachments to prevent duplicate items, (issue https://gitlab.com/phoenixlidar/accounts/-/issues/379#note_2385826999)
  // If oldRaw exists, only add attachments that are not already present in old attachments
  // Otherwise, include all transformed attachments
  const newAttachments = oldAttachments.length
    ? transformedAttachments.filter(attachment => !oldAttachments.some(oldAtt => oldAtt.id === attachment.id))
    : transformedAttachments

  return {
    ...raw,
    created_at: moment(created_at).format(config.DATETIME_FORMAT),
    updated_at: moment(updated_at).format(config.DATETIME_FORMAT),
    attachments: [...newAttachments, ...oldAttachments],
    drawing: transformedAttachments.filter(att => !att.isImage),
    photos: transformedAttachments.filter(att => att.isImage),
  }
}

export const convertRawSystemTypeImage = raw => {
  const {
    created_at,
    image_file,
    name,
  } = raw
  const splittedImageFile = (image_file || '').split('/')
  return {
    ...raw,
    created_at: moment(created_at).format(config.DATETIME_FORMAT),
    name: name || getBaseName(splittedImageFile[splittedImageFile.length - 1]),
  }
}
