import { put, takeLatest, fork, call } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { omit } from 'ramda'
// Project deps
import KeysActions, { KeyTypes } from './actions'
import { downloadFile } from 'utils/request'
import axios, { axiosClient } from 'utils/axios'
import config from 'config'
import { LicenseKeyState } from 'types/licenseKeys'
import { showErrorMessage } from 'utils/api'

function * downloadRoverLicense ({ subscriptionId, keyId }) {
  try {
    const data = {
      hostname: config.HOSTNAME,
      machine_id: config.HOSTNAME,
      state: LicenseKeyState.CHECKED_OUT,
    }
    document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;sessionid=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    const { data: roverLicense } = yield call(axiosClient.post, `/licensekeys/${keyId}/checkout/`, {
      h: data.hostname,
      m: data.machine_id,
      n: '',
    })
    yield put(KeysActions.editKeySuccess(subscriptionId, keyId, data))
    downloadFile('rover.license', JSON.stringify(omit(['message'], roverLicense), null, 2))
  } catch (e) {
    showErrorMessage(e)
  }
}

function * editKey ({ subscriptionId, keyId, data }) {
  yield put(KeysActions.editKeyLoading())
  try {
    const { data: { data: licenseKey } } = yield call(axios.patch, `/license_keys/${keyId}`, data)
    yield put(KeysActions.editKeySuccess(subscriptionId, keyId, undefined, licenseKey))
    toast.success('Key has been successfully updated!')
  } catch (e) {
    showErrorMessage(e)
    yield put(KeysActions.editKeyFailure())
  }
}

function * addKey ({ companyId, subscriptionId, data, onSuccess }) {
  yield put(KeysActions.addKeyLoading())
  try {
    const { data: { data: key } } = yield call(axios.post, `/subscriptions/${subscriptionId}/license_keys`, data)
    yield put(KeysActions.addKeySuccess(companyId, subscriptionId, key))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    toast.success('Key has been successfully created!')
  } catch (e) {
    showErrorMessage(e)
    yield put(KeysActions.addKeyFailure())
  }
}

function * editKeyWatcher () {
  yield takeLatest(KeyTypes.EDIT_KEY, editKey)
}

function * addKeyWatcher () {
  yield takeLatest(KeyTypes.ADD_KEY, addKey)
}
function * downloadRoverLicenseWatcher () {
  yield takeLatest(KeyTypes.DOWNLOAD_ROVER_LICENSE, downloadRoverLicense)
}

export default function * root () {
  yield fork(downloadRoverLicenseWatcher)
  yield fork(editKeyWatcher)
  yield fork(addKeyWatcher)
}
