import { getDefaultPagination } from 'modules/utils'
import { CheckoutSessionState } from 'utils/billingAgreement'

export const isSubscribed = state => {
  const subscriptionState = getSubscriptionState(state)
  return !(/cancel?led/i.test(subscriptionState) || !subscriptionState) // state.billingAgreement.get('subscribed')
}

export const getSubscriptionType = state => {
  return state.billingAgreement.get('subscriptionType')
}

export const getSubscriptionState = state => {
  return state.billingAgreement.get('subscriptionState')
}

export const isBillingAgreementLoading = state => {
  return state.billingAgreement.get('getBillingAgreementIsLoading')
}

export const getBillingAgreementError = state => {
  return state.billingAgreement.get('getBillingAgreementErrorMessage')
}

export const isBillingAgreementForActiveUserLoading = state => {
  return state.billingAgreement.get('getBillingAgreementForActiveUserIsLoading')
}

export const isExecuteStripeBillingAgreementLoading = state => {
  return state.billingAgreement.get('executeStripeBillingAgreementIsLoading')
}
export const isCancelSubscriptionsLoading = (state, companyId) => {
  return !!state.billingAgreement.get('cancelSubscriptionIsLoading')[companyId]
}

export const isStripeFormDialogOpened = (state, dialogState) => {
  return state.billingAgreement.get('isStripeFormDialogOpened') &&
    state.billingAgreement.get('stripeDialogState') === dialogState
}

export const getStripeFormDialogState = state => !!state.billingAgreement.get('stripePlanState')

export const isCancelStripeFormDialogOpened = state => {
  return state.billingAgreement.get('isStripeCancelDialogOpened')
}

export const isSubscribeRequestLoading = state => {
  return !!state.billingAgreement.get('createPayPalBillingAgreementIsLoading') ||
  !!state.billingAgreement.get('executePayPalBillingAgreementIsLoading') ||
  !!state.billingAgreement.get('executeStripeBillingAgreementIsLoading')
}

export const getCancelSubscriptionId = state => state.billingAgreement.get('cancelSubscriptionId')
export const getCancelSubscriptionCompanyId = state => state.billingAgreement.get('cancelSubscriptionCompanyId')

export const getStripePlanToken = state => state.billingAgreement.get('stripePlanToken')
export const getSalesProductDurationToken = state => state.billingAgreement.get('durationToken')
export const getSalesProducts = state => state.billingAgreement.get('salesProducts').filter(plan => !plan.isHidden)
export const getAllSalesProducts = state => state.billingAgreement.get('salesProducts')
export const getAllSalesBundles = state => state.billingAgreement.get('salesBundles')
export const getDurationsMap = state => state.billingAgreement.get('durationsMap')
export const getDurationTypes = state => state.billingAgreement.get('durationTypes')
export const getSalesProductsTypes = state => state.billingAgreement.get('salesProductsTypes')
export const isGetSalesProductsLoading = state => state.billingAgreement.get('getSalesProductsIsLoading')

export const getLicenseKeysAttachments = (state, licenseKeysIds) => {
  const attachments = state.billingAgreement.get('licenseKeyAttachments')
  const result = {}
  licenseKeysIds.forEach(licenseKeyId => {
    result[licenseKeyId] = attachments[licenseKeyId] || []
  })
  return result
}
export const isGetLicenseKeysAttachmentsLoading = (state, licenseKeysIds) => {
  const attachmentsLoading = state.billingAgreement.get('getLicenseKeyAttachmentsIsLoading')
  const result = {}
  licenseKeysIds.forEach(licenseKeyId => {
    result[licenseKeyId] = attachmentsLoading[licenseKeyId] || false
  })
  return result
}

export const getSubscriptionsInvoices = (state, subscriptionIds) => {
  const invoices = state.billingAgreement.get('invoices')
  const result = {}
  subscriptionIds.forEach(subscriptionId => {
    result[subscriptionId] = invoices[subscriptionId] || []
  })
  return result
}
export const isGetSubscriptionsInvoicesLoading = (state, subscriptionIds) => {
  const invoices = state.billingAgreement.get('getSubscriptionInvoicesIsLoading')
  const result = {}
  subscriptionIds.forEach(subscriptionId => {
    result[subscriptionId] = invoices[subscriptionId] || false
  })
  return result
}
export const getSubscriptionsExtendEvents = (state, subscriptionIds) => {
  const extendEvents = state.billingAgreement.get('extendEvents')
  const result = {}
  subscriptionIds.forEach(subscriptionId => {
    result[subscriptionId] = extendEvents[subscriptionId] || []
  })
  return result
}
export const getSubscriptionsCancelEvents = (state, subscriptionIds) => {
  const cancelEvents = state.billingAgreement.get('cancelEvents')
  const result = {}
  subscriptionIds.forEach(subscriptionId => {
    result[subscriptionId] = cancelEvents[subscriptionId] || []
  })
  return result
}
export const isGetSubscriptionsExtendEventsLoading = (state, subscriptionIds) => {
  const extendEventsLoading = state.billingAgreement.get('getSubscriptionExtendEventsIsLoading')
  return subscriptionIds.some(subscriptionId => extendEventsLoading[subscriptionId])
}
export const isExtendSubscriptionLoading = state => state.billingAgreement.get('extendSubscriptionIsLoading')
export const getSubscriptionCards = (state, subscriptionIds) => {
  const cards = state.billingAgreement.get('cards')
  const result = {}
  subscriptionIds.forEach(subscriptionId => {
    result[subscriptionId] = cards[subscriptionId] || null
  })
  return result
}
export const getSubscriptionInvoices = (state, subscriptionId) => state.billingAgreement.get('invoices')[subscriptionId] || []
export const getGrantTypes = state => state.billingAgreement.get('grantTypes') || []
export const getSubscriptionLicenseKeys = (state, subscriptionId) => state.billingAgreement.get('licenseKeys')[subscriptionId] || []
export const getSubscriptionsLicenseKeys = (state, subscriptionIds) => {
  const licenseKeys = state.billingAgreement.get('licenseKeys')
  const result = {}
  subscriptionIds.forEach(subscriptionId => {
    result[subscriptionId] = licenseKeys[subscriptionId] || []
  })
  return result
}
export const getSubscriptionCard = (state, subscriptionId) => state.billingAgreement.get('cards')[subscriptionId] || null

/**
 * Retrieves the license key added events for the given subscription IDs array.
 */
export const getLicenseKeyAddedEvents = (state, subscriptionIds) => {
  const addedEvents = state.billingAgreement.get('licenseKeyAddedEvents')
  const result = {}
  subscriptionIds.forEach(subscriptionId => {
    result[subscriptionId] = addedEvents[subscriptionId] || []
  })
  return result
}

export const isGetSubscriptionsLicenseKeysLoading = (state, subscriptionIds) => {
  const invoices = state.billingAgreement.get('getSubscriptionLicenseKeysIsLoading')
  const result = {}
  subscriptionIds.forEach(subscriptionId => {
    result[subscriptionId] = invoices[subscriptionId] || false
  })
  return result
}
export const isGetSubscriptionLicenseKeysLoading = (state, subscriptionId) => !!state.billingAgreement.get('getSubscriptionLicenseKeysIsLoading')[subscriptionId]
export const isGetSubscriptionInvoicesLoading = (state, subscriptionId) => !!state.billingAgreement.get('getSubscriptionInvoicesIsLoading')[subscriptionId]
export const getSubscriptionsCardsLoading = (state, subscriptionIds) => {
  const isLoading = state.billingAgreement.get('getSubscriptionCardIsLoading')
  const result = {}
  subscriptionIds.forEach(subscriptionId => {
    result[subscriptionId] = isLoading[subscriptionId] || false
  })
  return result
}
export const isGetSubscriptionCardLoading = (state, subscriptionId) => !!state.billingAgreement.get('getSubscriptionCardIsLoading')[subscriptionId]
export const getSubscriptionCardFailureMessage = (state, subscriptionId) => state.billingAgreement.get('getSubscriptionCardFailureMessage')[subscriptionId]

export const isUpdateSubscriptionCardLoading = (state, subscriptionId) => !!state.billingAgreement.get('updateSubscriptionCardIsLoading')[subscriptionId]
export const isStripeRefundLoading = state => state.billingAgreement.get('stripeRefundIsLoading')
export const isActivateOfflineSubscriptionIsLoading = (state, companyId) => !!state.billingAgreement.get('activateOfflineSubscriptionIsLoading')[companyId]
export const getDeactivateSubscriptionState = (state, subscriptionId) => state.billingAgreement.get('deactivateSubscriptionState')[subscriptionId]
export const getDeactivateSubscriptionFailureMessage = (state, subscriptionId) => state.billingAgreement.get('deactivateSubscriptionFailureMessage')[subscriptionId]

export const isGetSubscriptionGrantsLoading = (state, subscriptionId) => !!state.billingAgreement.get('getSubscriptionGrantsIsLoading')[subscriptionId]
export const getSubscriptionGrants = (state, subscriptionId) => state.billingAgreement.get('grants')[subscriptionId] || []
export const getAllSubscriptionGrants = state => state.billingAgreement.get('grants')

export const isGetPlanGrantsLoading = (state, planToken) => !!state.billingAgreement.get('getPlanGrantsIsLoading')[planToken]
export const getPlanGrants = (state, planToken) => state.billingAgreement.get('planGrants')[planToken] || []
export const getAllPlansGrants = state => state.billingAgreement.get('planGrants')

export const isCreatePlanGrantLoading = state => !!state.billingAgreement.get('createPlanGrantIsLoading')
export const isDeletePlanGrantLoading = state => !!state.billingAgreement.get('deletePlanGrantIsLoading')

export const isCreateKeyLoading = state => !!state.billingAgreement.get('addKeyIsLoading')
export const isTransferSubscriptionLoading = state => !!state.billingAgreement.get('transferSubscriptionIsLoading')

export const getAllSubscriptions = state => {
  return state.billingAgreement.get('allSubscriptions') || []
}
export const getAllSubscriptionsPagination = state => {
  return state.billingAgreement.get('allSubscriptionsPagination')
}
export const isGetAllSubscriptionsLoading = state => {
  return state.billingAgreement.get('allSubscriptionsLoading') || false
}

export const getCheckoutSessionId = state => state.billingAgreement.get('checkoutSessionState').id || null
export const getCheckoutSessionStatus = state => state.billingAgreement.get('checkoutSessionState').status
export const isGetCheckoutSessionLoading = state => getCheckoutSessionStatus(state) !== CheckoutSessionState.FAILED && getCheckoutSessionId(state)
export const getCheckoutSessionType = state => state.billingAgreement.get('checkoutSessionState').type || null
export const getCheckoutSessionText = state => state.billingAgreement.get('checkoutSessionState').text || ''
export const getCheckoutSessionTimeout = state => state.billingAgreement.get('checkoutSessionState').timeout || 0
export const getCheckoutSessionAttempts = state => state.billingAgreement.get('checkoutSessionState').attempts || 0

export const isGetSubscriptionDBLogsLoading = (state, subscriptionId) => state.billingAgreement.get('getSubscriptionDBLogsLoading')[subscriptionId]
export const getSubscriptionDBLogs = (state, subscriptionId) => state.billingAgreement.get('subscriptionLogs')[subscriptionId] || []
export const getSubscriptionDBLogsPagination = (state, subscriptionId) => state.billingAgreement.get('getSubscriptionDBLogsPagination')[subscriptionId] || getDefaultPagination()
