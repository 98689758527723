import { all, call, put, takeLatest, takeEvery, fork, select } from 'redux-saga/effects'
import { orderBy } from 'lodash'
import { toast } from 'react-toastify'
import CompaniesActions from 'modules/companies/actions'
// import GrantsActions from 'modules/grants/actions'
import UsersActions from 'modules/users/actions'
import { UrlTypes } from 'modules/url/actions'
import { omit } from 'ramda'
import { getLoggedUser } from 'modules/users/selectors'
import { showErrorMessage, getErrorMessage, createQueryString, getPaginationFromResponse, joinAll } from 'utils/api'
import axios from 'utils/axios'
import i18next from 'i18n'
import { patternPaypal } from 'utils/patterns'
// Local deps
import BillingAgreementActions, { BillingAgreementTypes } from './actions'
import { getCheckoutSessionType, getCheckoutSessionAttempts } from './selectors'
import { CheckoutSessionState, CheckoutSessionType, isPlanHasRoverFeature } from 'utils/billingAgreement'
import { isActiveSubscription, isStripeSubscription } from 'utils/subscription'
import { getSorting, stableSort } from 'utils/sort'
import history from 'config/history'
import { routeError, routeCompanySubscription, routeCompanySubscriptionGrant, routeCompanySubscriptionLicenseKey, redirectTo, routeCompanySubscriptionPaymentMethod } from 'utils/routing'
import { isUserAdmin, isUserAdminOrPLSUser } from 'utils/company'
import config from 'config'
import { addAttachments } from 'modules/utils'
import { getRoverEventAttachmentsUrl } from 'utils/roverEvents'
import { isIeLicenseKey } from 'types/licenseKeys'
import { filterLicenseKeys } from 'templates/utils'

// retry delay between requests to update session
const retryDelay = 5 * 1000
// 3 minutes is maximum time we will wait for session to be updated
const timeLimit = 3 * 60 * 1000
// maxium amount of attempts to be done
const attemptsLimit = timeLimit / retryDelay

function * executeStripeBillingAgreement ({ companyId, userId, data }) {
  yield put(BillingAgreementActions.executeStripeBillingAgreementLoading())
  try {
    if (companyId) {
      const { data: { data: session } } = yield call(axios.post, `/companies/${companyId}/checkout_session`, data)
      redirectTo(session.url, '_self')
      // console.log(sessionUrl)
      // toast.success(i18next.t('toast.billingAgreement.stripeSubscribeSuccess'))
      // yield put(BillingAgreementActions.executeStripeBillingAgreementSuccess('stripe'))
      // yield put(CompaniesActions.getCompanySubscriptions(companyId))
      // yield put(UsersActions.getSubscriptions(user.id))
      // yield put(BillingAgreementActions.getBillingAgreement())
      // if (userId) {
      //   yield put(GrantsActions.getGrants(userId))
      // }
      // yield put(BillingAgreementActions.closeStripeFormDialog())
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(BillingAgreementActions.executeStripeBillingAgreementFailure(getErrorMessage(e)))
  }
}

// Updated a card that is used for subscription
function * editSubscriptionCard ({ subscriptionId }) {
  try {
    const { data: { data: session } } = yield call(axios.post, `/subscriptions/${subscriptionId}/checkout_session`, {})
    redirectTo(session.url, '_self')
    // yield put(BillingAgreementActions.editSubscriptionCardSuccess(subscriptionId))
    // toast.success(i18next.t('toast.billingAgreement.cardUpdateSuccess'))
  } catch (e) {
  }
}

// Get subscription db logs
function * getSubscriptionDBLogs ({ subscriptionId, pagination, onSuccess }) {
  const { search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc' } = pagination
  yield put(BillingAgreementActions.getSubscriptionDBLogsLoading())
  try {
    const { data: { data: logs, pagination } } = yield call(axios.get, `/subscriptions/${subscriptionId}/db_logs${createQueryString({ search, pageSize, page, order, orderBy })}`)
    yield put(BillingAgreementActions.getSubscriptionDBLogsSuccess(subscriptionId, logs, getPaginationFromResponse(pagination.total, page, pageSize)))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(BillingAgreementActions.getSubscriptionDBLogsFailure(e.message || e))
  }
}

function * getCheckoutSession ({ sessionId, sessionState }) {
  const checkoutSessionType = yield select(state => getCheckoutSessionType(state))
  const attempts = yield select(state => getCheckoutSessionAttempts(state))
  yield put(BillingAgreementActions.getCheckoutSessionLoading(sessionId, sessionState))
  try {
    const { data: { data: session } } = yield call(axios.get, `/checkout_sessions/${sessionId}`)
    if (checkoutSessionType === CheckoutSessionType.CREATE) {
      if (session.status === 'complete') {
        if (session.payment_status === 'unpaid') {
          yield put(BillingAgreementActions.setCheckoutSession({ text: 'Payment in progress.' }))
        } else if (session.payment_status === 'paid') {
          if (session.subscription) {
            if (session.subscription.pls_subscription_id) {
              const subscriptionId = session.subscription.pls_subscription_id
              const { data: { data: subscription } } = yield call(axios.get, `/subscriptions/${subscriptionId}`)
              yield put(CompaniesActions.getCompanySubscriptionSuccess(subscription.company && subscription.company.id, subscriptionId, subscription))
              yield put(BillingAgreementActions.redirectToSubscription(subscriptionId))
              yield put(BillingAgreementActions.setCheckoutSession({ id: null, type: null }))
              toast.success('Your payment has been successfully processed!')
            } else {
              // This is called after we get another response after 5 seconds
              if (sessionState === 'complete-no-pls-sub') {
                if (attempts >= attemptsLimit) {
                  yield put(BillingAgreementActions.setCheckoutSession({
                    id: null,
                    status: CheckoutSessionState.FAILED,
                    text: `Your payment was successful but Phoenix LiDAR System subscription was not created, please contact support@phoenixlidar.com with a copy of this message. Session id: ${sessionId}, subscription token: ${session.subscription.id}. Please do not try to re-subscribe as that may cause duplicate payments.`,
                  }))
                } else {
                  yield put(BillingAgreementActions.setCheckoutSession({ attempts: attempts + 1 }))
                }
              } else {
                // Change state of the session timer to 5 seconds
                yield put(BillingAgreementActions.setCheckoutSession({ status: 'complete-no-pls-sub', timeout: retryDelay, attempts: 0 }))
              }
            }
          } else {
            // This is called after we get another response after 10 seconds
            if (sessionState === 'complete-no-sub') {
              if (attempts >= attemptsLimit) {
                yield put(BillingAgreementActions.setCheckoutSession({
                  id: null,
                  status: CheckoutSessionState.FAILED,
                  text: `Your payment was successful but a subscription was not created, please contact support@phoenixlidar.com with a copy of this message. Session id: ${sessionId}. Please do not try to re-subscribe as that may cause duplicate payments.`,
                }))
              }
            } else {
              // Change state of the session timer to 10 seconds
              yield put(BillingAgreementActions.setCheckoutSession({ status: 'complete-no-sub', timeout: retryDelay, attempts: 0 }))
            }
          }
        } else {
          yield put(BillingAgreementActions.setCheckoutSession({
            id: null,
            status: CheckoutSessionState.FAILED,
            text: `Your payment has failed, please try again or contact support@phoenixlidar.com. Session id: ${sessionId}. Please do not try to re-subscribe as that may cause duplicate payments.`,
          }))
        }
      } else if (session.status === 'expired') {
        yield put(BillingAgreementActions.setCheckoutSession({
          id: null,
          status: CheckoutSessionState.FAILED,
          text: `Session expired. Session id: ${sessionId}`,
        }))
      } else if (session.status === 'open') {
        yield put(BillingAgreementActions.setCheckoutSession({ text: 'Payment in progress.' }))
      }
    } else if (checkoutSessionType === CheckoutSessionType.CREATE_SCHEDULED) {
      if (session.status === 'complete') {
        if (session.subscription) {
          if (session.subscription.pls_subscription_id) {
            const subscriptionId = session.subscription.pls_subscription_id
            const { data: { data: subscription } } = yield call(axios.get, `/subscriptions/${subscriptionId}`)
            yield put(CompaniesActions.getCompanySubscriptionSuccess(subscription.company && subscription.company.id, subscriptionId, subscription))
            yield put(BillingAgreementActions.redirectToSubscription(subscriptionId))
            yield put(BillingAgreementActions.setCheckoutSession({ id: null, type: null }))
            toast.success('Your payment has been successfully processed!')
          } else {
            // This is called after we get another response after 5 seconds
            if (sessionState === 'complete-no-pls-sub') {
              // After 20 seconds if no success response - it's failed
              if (attempts >= attemptsLimit) {
                yield put(BillingAgreementActions.setCheckoutSession({
                  id: null,
                  status: CheckoutSessionState.FAILED,
                  text: `Your payment was successful but Phoenix LiDAR System subscription was not created, please contact support@phoenixlidar.com with a copy of this message. Session id: ${sessionId}, subscription token: ${session.subscription.id}. Please do not try to re-subscribe as that may cause duplicate payments.`,
                }))
              } else {
                yield put(BillingAgreementActions.setCheckoutSession({ attempts: attempts + 1 }))
              }
            } else {
              // Change state of the session timer to 5 seconds
              yield put(BillingAgreementActions.setCheckoutSession({ status: 'complete-no-pls-sub', timeout: retryDelay, attempts: 0 }))
            }
          }
        } else {
          // This is called after we get another response after 10 seconds
          if (sessionState === 'complete-no-sub') {
            if (attempts >= attemptsLimit) {
              yield put(BillingAgreementActions.setCheckoutSession({
                id: null,
                status: CheckoutSessionState.FAILED,
                text: `Your payment was successful but a subscription was not created, please contact support@phoenixlidar.com with a copy of this message. Session id: ${sessionId}. Please do not try to re-subscribe as that may cause duplicate payments.`,
              }))
            } else {
              yield put(BillingAgreementActions.setCheckoutSession({ attempts: attempts + 1 }))
            }
          } else {
            // Change state of the session timer to 10 seconds
            yield put(BillingAgreementActions.setCheckoutSession({ status: 'complete-no-sub', timeout: retryDelay, attempts: 0 }))
          }
        }
      } else if (session.status === 'expired') {
        yield put(BillingAgreementActions.setCheckoutSession({
          id: null,
          status: CheckoutSessionState.FAILED,
          text: `Session expired. Session id: ${sessionId}`,
        }))
      } else if (session.status === 'open') {
        yield put(BillingAgreementActions.setCheckoutSession({ text: 'Payment in progress.' }))
      }
    } else if (checkoutSessionType === CheckoutSessionType.EDIT_CARD) {
      const isCardUpdated = session.subscription && session.subscription.update_payment_method
      if (session.status === 'complete' && isCardUpdated) {
        // We can parse subscription_id from the success/cancel_url to make it simplier
        const subscriptionId = session.success_url.split('/subscriptions')[1].split('checkout_sessions')[0].replace(/\//g, '')
        const { data: { data: subscription } } = yield call(axios.get, `/subscriptions/${subscriptionId}`)
        toast.success('Your card details has been successfully updated!')
        yield put(CompaniesActions.getCompanySubscriptionSuccess(subscription.company && subscription.company.id, subscriptionId, subscription))
        yield put(BillingAgreementActions.getSubscriptionCard(subscriptionId))
        yield put(BillingAgreementActions.redirectToSubscription(subscriptionId, 'payment'))
        yield put(BillingAgreementActions.setCheckoutSession({ id: null, type: null }))
      } else {
        // After 20 seconds if no success response - it's failed
        if (attempts >= attemptsLimit) {
          yield put(BillingAgreementActions.setCheckoutSession({
            id: null,
            status: CheckoutSessionState.FAILED,
            text: `We were unable to update your card information, please contact support@phoenixlidar.com with a copy of this message. Session id: ${sessionId}, subscription token: ${session.subscription.id}.`,
          }))
        } else {
          yield put(BillingAgreementActions.setCheckoutSession({ attempts: attempts + 1 }))
        }
      }
    }
    yield put(BillingAgreementActions.getCheckoutSessionSuccess(session))
  } catch (e) {
    showErrorMessage(e)
    yield put(BillingAgreementActions.getCheckoutSessionFailure(getErrorMessage(e)))
  }
}

function * createPayPalBillingAgreement () {
  yield put(BillingAgreementActions.createPaypalBillingAgreementLoading())
  try {
    const user = yield select(state => getLoggedUser(state))
    const { data: { data: { approval_url: approvalUrl } } } = yield call(axios.post, `/users/${user.id}/billing_agreement`, null)
    toast.success(i18next.t('toast.billingAgreement.paypalCreateSuccess'))
    yield put(BillingAgreementActions.createPaypalBillingAgreementSuccess(approvalUrl))
  } catch (e) {
    yield put(BillingAgreementActions.createPaypalBillingAgreementFailure(getErrorMessage(e)))
  }
}

function * executePayPalBillingAgreement ({ paymentToken }) {
  yield put(BillingAgreementActions.executePaypalBillingAgreementLoading())
  try {
    const user = yield select(state => getLoggedUser(state))
    yield call(axios.put, `/users/${user.id}/billing_agreement?payment_token=${paymentToken}`, null)
    toast.success(i18next.t('toast.billingAgreement.paypalSubscribeSuccess'))
    yield put(BillingAgreementActions.executePaypalBillingAgreementSuccess('paypal'))
  } catch (e) {
    yield put(BillingAgreementActions.executePaypalBillingAgreementFailure(getErrorMessage(e)))
  }
}

function * changeUrl ({ url }) {
  const parameters = patternPaypal.match(url)
  if (parameters) {
    const paypalSuccess = parameters.status === 'success'
    const paymentToken = parameters.token
    if (!paypalSuccess) {
      toast.error(i18next.t('toast.billingAgreement.changeUrlError'))
      yield put(BillingAgreementActions.setPaypalToken(paypalSuccess))
    } else {
      yield put(BillingAgreementActions.setPaypalToken(paypalSuccess, paymentToken))
      yield put(BillingAgreementActions.executePaypalBillingAgreement(paymentToken))
    }
  }
}
// Activate non-recurring subscription for a company
function * activateOfflineSubscription ({ companyId, payload, onSuccess }) {
  yield put(BillingAgreementActions.activateOfflineSubscriptionLoading(companyId))
  try {
    const attachments = payload.license_key_attachments
    const rovers = payload.rovers
    const licenseKey = payload.manual_license_key
    const body = omit(['license_key_attachments', 'manual_license_key'], payload)
    let subscriptionAddToReducer = null
    const { data: { data: subscription } } = yield call(
      axios.post,
      `/companies/${companyId}/offline_subscription`,
      body,
    )
    subscriptionAddToReducer = subscription
    const subscriptionId = subscription.id
    if (licenseKey) {
      const { data: { data: licenseKeyResponse } } = yield call(
        axios.post,
        `/subscriptions/${subscriptionId}/license_keys`,
        licenseKey,
      )
      if (attachments && attachments.length > 0) {
        yield call(addAttachments, licenseKeyResponse.id, 'licenseKey', attachments)
        const { data: { data: subscription } } = yield call(axios.get, `/subscriptions/${subscriptionId}`)
        subscriptionAddToReducer = subscription
      }
    }
    toast.success('Non-recurring subscription has been activated!')
    yield put(BillingAgreementActions.activateOfflineSubscriptionSuccess(companyId, subscriptionAddToReducer))
    yield put(UsersActions.setActiveUserSubscription(subscriptionAddToReducer))
    if (rovers && rovers.length > 0) {
      yield put(CompaniesActions.getCompanyRovers(companyId))
      yield put(CompaniesActions.addRovers(body.rovers, subscriptionAddToReducer))
    }
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(BillingAgreementActions.activateOfflineSubscriptionFailure(companyId, getErrorMessage(e)))
  }
}
// Extends non-recurring subscription for a company
function * extendOfflineSubscription ({ companyId, subscriptionId, payload, onSuccess }) {
  yield put(BillingAgreementActions.extendOfflineSubscriptionLoading(companyId, subscriptionId))
  try {
    yield call(axios.post, `/subscriptions/${subscriptionId}/offline_extending`, payload)
    yield put(BillingAgreementActions.extendOfflineSubscriptionSuccess(companyId, subscriptionId, { ...payload, end_date: payload.end_date.format(config.DATETIME_FORMAT) }))
    yield put(BillingAgreementActions.getExtendEvents(subscriptionId))
    yield put(BillingAgreementActions.getSubscriptionGrants(subscriptionId))
    toast.success(i18next.t('toast.user.extendSubscriptionSuccess'))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(BillingAgreementActions.extendOfflineSubscriptionFailure(companyId, subscriptionId))
  }
}
// Retrieves a list of extending events for a subscription
function * getExtendEvents ({ subscriptionId, onSuccess }) {
  yield put(BillingAgreementActions.getExtendEventsLoading(subscriptionId))
  try {
    const { data: { data: extendindEvents } } = yield call(axios.get, `/subscriptions/${subscriptionId}/extending_events`)
    yield put(BillingAgreementActions.getExtendEventsSuccess(subscriptionId, extendindEvents))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(BillingAgreementActions.getExtendEventsFailure(subscriptionId))
  }
}
// Retrieves a list of cancel events for a subscription
function * getCancelEvents ({ subscriptionId, onSuccess }) {
  yield put(BillingAgreementActions.getCancelEventsLoading(subscriptionId))
  try {
    const { data: { data: cancelingEvents } } = yield call(axios.get, `/subscriptions/${subscriptionId}/cancel_events`)
    yield put(BillingAgreementActions.getCancelEventsSuccess(subscriptionId, cancelingEvents))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(BillingAgreementActions.getCancelEventsFailure(subscriptionId))
  }
}
// Deactive individual non-recurring subscription for a comapny
function * deactivateSubscriptions ({ companyId, subscriptions, data, onSuccess }) {
  yield put(BillingAgreementActions.deactivateSubscriptionsLoading(companyId))
  const loggedUser = yield select(state => getLoggedUser(state))
  try {
    const failedResults = []
    const successResults = []
    let isSomeStripeSubscription = false
    for (let i = 0; i < subscriptions.length; i++) {
      if (failedResults.length > 0) {
        break
      }
      const subscription = subscriptions[i]
      const subscriptionId = subscriptions[i].id
      yield put(BillingAgreementActions.deactivateSubscriptionLoading(subscriptionId))
      try {
        if (isStripeSubscription(subscription.subscription_type)) {
          isSomeStripeSubscription = true
          const { data: { data: subscription } } = yield call(axios.put, `/stripe_subscription/${subscriptionId}`, data)
          successResults.push(subscription)
        } else {
          const { data: { data: subscription } } = yield call(axios.put, `/offline_subscription/${subscriptionId}/cancel`, data)
          successResults.push(subscription)
        }
        yield put(BillingAgreementActions.deactivateSubscriptionSuccess(subscriptionId))
      } catch (e) {
        failedResults.push(subscriptionId)
        yield put(BillingAgreementActions.deactivateSubscriptionFailure(subscriptionId, getErrorMessage(e)))
      }
    }
    if (failedResults.length > 0) {
      toast.error(i18next.t('toast.billingAgreement.deactivateOfflineSubscriptionFailed'))
      yield put(BillingAgreementActions.deactivateSubscriptionsFailure(companyId))
    } else {
      if (isSomeStripeSubscription) {
        // toast.success(i18next.t('toast.billingAgreement.stripeUnsubscribeSuccess'))
        yield put(BillingAgreementActions.closeStripeCancelDialog())
        yield put(BillingAgreementActions.cancelStripeBillingAgreementSuccess())
        yield put(UsersActions.updateUser({ id: loggedUser.id, ...data }, false))
      }
      // toast.success(i18next.t('toast.billingAgreement.deactivateOfflineSubscriptionSuccess'))
      yield put(BillingAgreementActions.deactivateSubscriptionsSuccess(companyId))
      const subscription = subscriptions[subscriptions.length - 1]
      if (isPlanHasRoverFeature(subscription.plan) && isActiveSubscription(subscription.subscription_state)) {
        const [firstLicenseKey] = subscription.license_keys
        if (firstLicenseKey) {
          const firstRoverSerial = firstLicenseKey.id.split('-')[0]
          yield put(CompaniesActions.removeRover(firstRoverSerial))
        }
      }
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }
    if (successResults.length > 0) {
      yield put(BillingAgreementActions.deactivateOfflineSubscriptionsSuccess(companyId, successResults))
      for (let i = 0; i < successResults.length; i++) {
        const subscriptionId = successResults[i].id
        yield put(BillingAgreementActions.getCancelEvents(subscriptionId))
      }
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(BillingAgreementActions.deactivateSubscriptionsFailure(companyId, getErrorMessage(e)))
  }
}
// Retrieve a list of available plans both with grant types
function * getSalesProducts () {
  yield put(BillingAgreementActions.getSalesProductsLoading())
  try {
    const [
      { data: { data: salesProducts } },
      { data: { data: grantTypes } },
      { data: { data: salesBundles } },
    ] = yield all([
      call(axios.get, '/sales_products'),
      call(axios.get, '/grant_types'),
      call(axios.get, '/sales_bundles'),
    ])
    yield put(BillingAgreementActions.getSalesProductsSuccess(
      salesProducts,
      stableSort(grantTypes, getSorting('asc', 'id')),
      salesBundles,
    ))
  } catch (e) {
    yield put(BillingAgreementActions.getSalesProductsFailure())
  }
}
// Retrieve a list of invoices for individual subscription
function * getSubscriptionInvoices ({ subscriptionId }) {
  yield put(BillingAgreementActions.getSubscriptionInvoicesLoading(subscriptionId))
  try {
    const loggedUser = yield select(getLoggedUser)
    const isCurrentUserSuperAdmin = isUserAdmin(loggedUser)
    const { data: { data: invoices } } = yield call(axios.get, `/subscription/${subscriptionId}/invoices`)
    // const tasks = []
    /*
    for (let i = 0; i < invoices.length; i++) {
      const invoice = invoices[i]
      tasks.push(call(axios.get, `/charges/${invoice.charge}`,))
    }
    const result = yield all(tasks)
    const charges = result.reduce((allCharges, { data: { data: charge } }) => [...allCharges, charge], [])
    */
    if (isCurrentUserSuperAdmin) {
      yield put(BillingAgreementActions.getSubscriptionInvoicesSuccess(
        subscriptionId,
        invoices,
      ))
    } else {
      yield put(BillingAgreementActions.getSubscriptionInvoicesSuccess(
        subscriptionId,
        invoices,
        [],
      ))
    }
  } catch (e) {
    yield put(BillingAgreementActions.getSubscriptionInvoicesFailure(subscriptionId, getErrorMessage(e)))
  }
}

function * retryInvoice ({ subscriptionId, invoiceId }) {
  yield put(BillingAgreementActions.setRetryPaymentState({ text: 'Payment in progress.', status: CheckoutSessionState.PENDING, id: null, type: 'retry-invoice' }))
  try {
    const { data: { data: invoice } } = yield call(axios.post, `/invoices/${invoiceId}/pay`, {})
    if (invoice.status === 'paid') {
      toast.success('Your payment has been successfully processed!')
      yield put(BillingAgreementActions.setRetryPaymentState({ type: null }))
      yield put(BillingAgreementActions.getSubscriptionInvoices(subscriptionId))
      const { data: { data: subscription } } = yield call(axios.get, `/subscriptions/${subscriptionId}`)
      yield put(CompaniesActions.getCompanySubscriptionSuccess(subscription.company && subscription.company.id, subscriptionId, subscription))
    } else {
      yield put(BillingAgreementActions.setRetryPaymentState({ status: 'in-progress', timeout: retryDelay, attempts: 0, id: subscriptionId }))
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e)
    yield put(BillingAgreementActions.setRetryPaymentState({ id: null, status: CheckoutSessionState.FAILED, text: errorMessage }))
  }
}
function * retryGetSubscription ({ subscriptionId }) {
  const attempts = yield select(state => state.billingAgreement.get('retryPaymentState').attempts)
  try {
    const { data: { data: subscription } } = yield call(axios.get, `/subscriptions/${subscriptionId}`)
    if (isActiveSubscription(subscription.status)) {
      toast.success('Your payment has been successfully processed!')
      yield put(BillingAgreementActions.setRetryPaymentState({ id: null, type: null }))
      yield put(BillingAgreementActions.getSubscriptionInvoices(subscriptionId))
      yield put(CompaniesActions.getCompanySubscriptionSuccess(subscription.company && subscription.company.id, subscriptionId, subscription))
    } else if (attempts >= attemptsLimit) {
      yield put(BillingAgreementActions.setRetryPaymentState({
        status: CheckoutSessionState.FAILED,
        text: 'Your payment was successful but Phoenix LiDAR System subscription was not updated, please contact support@phoenixlidar.com. Please do not try to re-subscribe as that may cause duplicate payments.',
        id: null,
      }))
    }
  } catch (e) {
  }
}
// Retrieve a list of grant types for individual subscription
function * getSubscriptionGrants ({ subscriptionId }) {
  yield put(BillingAgreementActions.getSubscriptionGrantsLoading(subscriptionId))
  try {
    const { data: { data: grants } } = yield call(axios.get, `/subscriptions/${subscriptionId}/grants`)
    yield put(BillingAgreementActions.getSubscriptionGrantsSuccess(
      subscriptionId,
      grants,
    ))
  } catch (e) {
    yield put(BillingAgreementActions.getSubscriptionGrantsFailure(subscriptionId, getErrorMessage(e)))
  }
}
// Retrieve a list of grant types for individual subscription
function * getPlanGrants ({ planToken }) {
  yield put(BillingAgreementActions.getPlanGrantsLoading(planToken))
  try {
    const { data: { data: grants } } = yield call(axios.get, `/plans/${planToken}/grant_types`)
    yield put(BillingAgreementActions.getPlanGrantsSuccess(
      planToken,
      grants,
    ))
  } catch (e) {
    yield put(BillingAgreementActions.getPlanGrantsFailure(planToken, getErrorMessage(e)))
  }
}
// Create a grant for plan
function * createPlanGrant ({ productId, grant }) {
  yield put(BillingAgreementActions.createPlanGrantLoading(productId))
  try {
    const url = `/sales_products/${productId}/sales_product_grant_type`
    const { data: { data: newGrant } } = yield call(axios.post, url, {
      type_grant_id: grant.grantType,
      value: grant.value.toString(),
    })
    // const returnedGrant = convertRawGrant(result.response.data)
    yield put(BillingAgreementActions.createPlanGrantSuccess(productId, newGrant)) // allowed
    toast.success(i18next.t('toast.grant.createSuccess'))
  } catch (e) {
    showErrorMessage(e)
    yield put(BillingAgreementActions.createPlanGrantFailure(productId, getErrorMessage(e)))
  }
}
// Delete a grant for plan
function * deletePlanGrant ({ productId, grantId, onSuccess }) {
  yield put(BillingAgreementActions.deletePlanGrantLoading(productId))
  try {
    yield call(axios.delete, `/sales_product_grant_type/${grantId}`)
    // const returnedGrant = convertRawGrant(result.response.data)
    yield put(BillingAgreementActions.deletePlanGrantSuccess(productId, grantId)) // allowed
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    toast.success(i18next.t('toast.grant.deleteSuccess'))
  } catch (e) {
    yield put(BillingAgreementActions.deletePlanGrantFailure(productId, getErrorMessage(e)))
  }
}
// Retrieve a list of license keys for individual subscription
function * getSubscriptionLicenseKeys ({ subscriptionId, onSuccess = null }) {
  yield put(BillingAgreementActions.getSubscriptionLicenseKeysLoading(subscriptionId))
  try {
    const { data: { data: licenseKeys } } = yield call(axios.get, `/subscription/${subscriptionId}/license_keys`)
    const loggedUser = yield select(getLoggedUser)
    const isAdminOrPls = isUserAdminOrPLSUser(loggedUser)
    const sortedLk = orderBy(licenseKeys, ['updated'], ['desc'])
    let filteredLicenseKeys = []
    // Show subscription license keys depending on user permissions
    if (isAdminOrPls) {
      // Show all license keys for admin and PLS users
      filteredLicenseKeys = sortedLk
    } else {
      // Filter out subscriptions with IE license key and show only the one with the highest version or the newest one for customers.
      const hasIeKey = sortedLk.some(key => isIeLicenseKey(key.type))
      if (hasIeKey) {
        filteredLicenseKeys = filterLicenseKeys(sortedLk)
      }
    }
    yield put(BillingAgreementActions.getSubscriptionLicenseKeysSuccess(
      subscriptionId,
      filteredLicenseKeys,
    ))
    if (typeof onSuccess === 'function') {
      onSuccess(filteredLicenseKeys)
    }
  } catch (e) {
    yield put(BillingAgreementActions.getSubscriptionLicenseKeysFailure(subscriptionId, getErrorMessage(e)))
  }
}
// Retrieve the attachments for each licenseKeyId from licenseKeysIds
function * getLicenseKeysAttachments ({ licenseKeysIds }) {
  yield put(BillingAgreementActions.getLicenseKeysAttachmentsLoading(licenseKeysIds))
  try {
    const tasks = []
    for (let i = 0; i < licenseKeysIds.length; i++) {
      const licenseKeyId = licenseKeysIds[i]
      const url = getRoverEventAttachmentsUrl(licenseKeyId, 'licenseKey')
      tasks.push(yield fork(axios.get, url))
    }
    const licenseKeysAttachmentsResults = yield joinAll(tasks)
    const attachmentsByLicenseKey = licenseKeysAttachmentsResults.reduce((all, { data: { data: licenseKeyAttachments } }, index) => ({
      ...all,
      [licenseKeysIds[index]]: licenseKeyAttachments,
    }), {})

    yield put(BillingAgreementActions.getLicenseKeysAttachmentsSuccess(
      licenseKeysIds,
      attachmentsByLicenseKey,
    ))
  } catch (e) {
    yield put(BillingAgreementActions.getLicenseKeysAttachmentsFailure(licenseKeysIds, getErrorMessage(e)))
  }
}

// Retrieve a list of license key added events for individual subscription
function * getLicenseKeyAddedEvents ({ subscriptionId }) {
  yield put(BillingAgreementActions.getLicenseKeyAddedEventsLoading(subscriptionId))
  try {
    const { data: { data: licenseKeyAddedEvents } } = yield call(axios.get, `/subscriptions/${subscriptionId}/license_key_added_events`)
    yield put(BillingAgreementActions.getLicenseKeyAddedEventsSuccess(
      subscriptionId,
      licenseKeyAddedEvents,
    ))
  } catch (e) {
    yield put(BillingAgreementActions.getLicenseKeyAddedEventsFailure(subscriptionId, getErrorMessage(e)))
  }
}
// Refund individual Stripe invoice
function * stripeRefund ({ subscriptionId, chargeId, reason }) {
  yield put(BillingAgreementActions.stripeRefundLoading(subscriptionId, chargeId))
  try {
    const { data: { data: refund } } = yield call(axios.post, `/subscriptions/${subscriptionId}/refunds`, {
      charge: chargeId,
      reason,
    })
    yield put(BillingAgreementActions.stripeRefundSuccess(
      subscriptionId,
      chargeId,
      refund,
    ))
    toast.success(i18next.t('toast.billingAgreement.refundSuccess'))
  } catch (e) {
    yield put(BillingAgreementActions.stripeRefundFailure(subscriptionId, chargeId, getErrorMessage(e)))
  }
}

// Retrieve a card that is used for subscription
function * getSubscriptionCard ({ subscriptionId }) {
  yield put(BillingAgreementActions.getSubscriptionCardLoading(subscriptionId))
  try {
    const { data: { data: card } } = yield call(axios.get, `/subscriptions/${subscriptionId}/card`)
    yield put(BillingAgreementActions.getSubscriptionCardSuccess(subscriptionId, card))
  } catch (e) {
    yield put(BillingAgreementActions.getSubscriptionCardFailure(subscriptionId, getErrorMessage(e)))
  }
}

// Updated a card that is used for subscription
function * updateSubscriptionCard ({ subscriptionId, token }) {
  yield put(BillingAgreementActions.updateSubscriptionCardLoading(subscriptionId))
  try {
    const { data: { data: card } } = yield call(axios.patch, `/subscriptions/${subscriptionId}/card`, {
      card_token: token,
    })
    yield put(BillingAgreementActions.getSubscriptionCardSuccess(subscriptionId, card))
    yield put(BillingAgreementActions.closeStripeFormDialog())
    yield put(BillingAgreementActions.updateSubscriptionCardSuccess(subscriptionId))
    toast.success(i18next.t('toast.billingAgreement.cardUpdateSuccess'))
  } catch (e) {
    yield put(BillingAgreementActions.updateSubscriptionCardFailure(subscriptionId))
  }
}

function * redirectToSubscription ({ subscriptionId, subscriptionTab, componentId }) {
  try {
    const { data: { data: subscription } } = yield call(axios.get, `/subscriptions/${subscriptionId}`)
    if (subscription.company && subscription.company.id) {
      const companyId = subscription.company.id
      const args = [companyId, subscriptionId, componentId, subscription && subscription.is_archived ? 'show_archived=true' : '']
      if (!subscriptionTab) {
        history.push(routeCompanySubscription(...args))
      } else {
        if (subscriptionTab === 'grants') {
          history.push(routeCompanySubscriptionGrant(...args))
        }
        if (subscriptionTab === 'keys') {
          history.push(routeCompanySubscriptionLicenseKey(...args))
        }
        if (subscriptionTab === 'payment') {
          history.push(routeCompanySubscriptionPaymentMethod(...args))
        }
      }
    }
  } catch (e) {
    history.push(routeError())
  }
}

// Performing batch request to transfer multiple subscriptions to a different company
function * transferSubscriptions ({ subscriptionIds, oldCompanyId, data, succesCallback }) {
  yield put(BillingAgreementActions.transferSubscriptionsLoading(subscriptionIds))
  try {
    const tasks = []
    for (let i = 0; i < subscriptionIds.length; i++) {
      const subscriptionId = subscriptionIds[i]
      tasks.push(yield fork(
        axios.put,
        `/subscriptions/${subscriptionId}`,
        data,
      ))
    }
    yield joinAll(tasks)
    yield put(BillingAgreementActions.transferSubscriptionsSuccess(subscriptionIds, oldCompanyId, data))
    if (typeof succesCallback === 'function') {
      succesCallback()
    }
    toast.success(i18next.t('toast.billingAgreement.transferSuccess'))
  } catch (e) {
    yield put(BillingAgreementActions.transferSubscriptionsFailure(subscriptionIds))
  }
}
// Retrieve a list of all subscriptions
function * getAllSubscriptions ({ pagination, filterString }) {
  const { search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc' } = pagination
  yield put(BillingAgreementActions.getAllSubscriptionsLoading())
  try {
    const { data: { data: subscriptions, pagination } } = yield call(axios.get, `/subscriptions/all${createQueryString({ search, pageSize, page, order, orderBy })}${filterString ? `&${filterString}` : ''}`)
    yield put(BillingAgreementActions.getAllSubscriptionsSuccess(subscriptions, getPaginationFromResponse(pagination.total, page, pageSize, order, orderBy)))
  } catch (e) {
    yield put(BillingAgreementActions.getAllSubscriptionsFailure())
  }
}

// Watchers
function * activateOfflineSubscriptionWatcher () {
  yield takeEvery(BillingAgreementTypes.ACTIVATE_OFFLINE_SUBSCRIPTION, activateOfflineSubscription)
}

function * extendOfflineSubscriptionWatcher () {
  yield takeLatest(BillingAgreementTypes.EXTEND_OFFLINE_SUBSCRIPTION, extendOfflineSubscription)
}

function * getExtendEventsWatcher () {
  yield takeEvery(BillingAgreementTypes.GET_EXTEND_EVENTS, getExtendEvents)
}

function * getCancelEventsWatcher () {
  yield takeEvery(BillingAgreementTypes.GET_CANCEL_EVENTS, getCancelEvents)
}

function * deactivateSubscriptionsWatcher () {
  yield takeLatest(BillingAgreementTypes.DEACTIVATE_SUBSCRIPTIONS, deactivateSubscriptions)
}

function * executeStripeBillingAgreementWatcher () {
  yield takeLatest(BillingAgreementTypes.EXECUTE_STRIPE_BILLING_AGREEMENT, executeStripeBillingAgreement)
}

function * executePayPalBillingAgreementWatcher () {
  yield takeLatest(BillingAgreementTypes.EXECUTE_PAYPAL_BILLING_AGREEMENT, executePayPalBillingAgreement)
}

function * createPayPalBillingAgreementWatcher () {
  yield takeLatest(BillingAgreementTypes.CREATE_PAYPAL_BILLING_AGREEMENT, createPayPalBillingAgreement)
}

function * getSalesProductsWatcher () {
  yield takeLatest(BillingAgreementTypes.GET_SALES_PRODUCTS, getSalesProducts)
}

function * getSubscriptionInvoicesWatcher () {
  yield takeEvery(BillingAgreementTypes.GET_SUBSCRIPTION_INVOICES, getSubscriptionInvoices)
}

function * getSubscriptionGrantsWatcher () {
  yield takeEvery(BillingAgreementTypes.GET_SUBSCRIPTION_GRANTS, getSubscriptionGrants)
}

function * getPlanGrantsWatcher () {
  yield takeLatest(BillingAgreementTypes.GET_PLAN_GRANTS, getPlanGrants)
}

function * createPlanGrantWatcher () {
  yield takeLatest(BillingAgreementTypes.CREATE_PLAN_GRANT, createPlanGrant)
}

function * deletePlanGrantWatcher () {
  yield takeLatest(BillingAgreementTypes.DELETE_PLAN_GRANT, deletePlanGrant)
}

function * getSubscriptionLicenseKeysWatcher () {
  yield takeEvery(BillingAgreementTypes.GET_SUBSCRIPTION_LICENSE_KEYS, getSubscriptionLicenseKeys)
}
function * getLicenseKeysAttachmentsWatcher () {
  yield takeEvery(BillingAgreementTypes.GET_LICENSE_KEYS_ATTACHMENTS, getLicenseKeysAttachments)
}

function * getLicenseKeyAddedEventsWatcher () {
  yield takeEvery(BillingAgreementTypes.GET_LICENSE_KEY_ADDED_EVENTS, getLicenseKeyAddedEvents)
}

function * stripeRefundWatcher () {
  yield takeLatest(BillingAgreementTypes.STRIPE_REFUND, stripeRefund)
}

function * getSubscriptionCardWatcher () {
  yield takeLatest(BillingAgreementTypes.GET_SUBSCRIPTION_CARD, getSubscriptionCard)
}

function * updateSubscriptionCardWatcher () {
  yield takeLatest(BillingAgreementTypes.UPDATE_SUBSCRIPTION_CARD, updateSubscriptionCard)
}

function * changeUrlWatcher () {
  yield takeLatest(UrlTypes.CHANGE_URL, changeUrl)
}

function * redirectToSubscriptionWatcher () {
  yield takeLatest(BillingAgreementTypes.REDIRECT_TO_SUBSCRIPTION, redirectToSubscription)
}

function * transferSubscriptionWatcher () {
  yield takeEvery(BillingAgreementTypes.TRANSFER_SUBSCRIPTIONS, transferSubscriptions)
}

function * getAllSubscriptionsWatcher () {
  yield takeEvery(BillingAgreementTypes.GET_ALL_SUBSCRIPTIONS, getAllSubscriptions)
}

function * getCheckoutSessionWatcher () {
  yield takeLatest(BillingAgreementTypes.GET_CHECKOUT_SESSION, getCheckoutSession)
}

function * editSubscriptionCardWatcher () {
  yield takeLatest(BillingAgreementTypes.EDIT_SUBSCRIPTION_CARD, editSubscriptionCard)
}
function * retryInvoiceWatcher () {
  yield takeLatest(BillingAgreementTypes.RETRY_INVOICE, retryInvoice)
}
function * retryGetSubscriptionWatcher () {
  yield takeLatest(BillingAgreementTypes.RETRY_GET_SUBSCRIPTION, retryGetSubscription)
}
function * getSubscriptionDBLogsWatcher () {
  yield takeLatest(BillingAgreementTypes.GET_SUBSCRIPTION_DB_LOGS, getSubscriptionDBLogs)
}

export default function * root () {
  yield fork(activateOfflineSubscriptionWatcher)
  yield fork(deactivateSubscriptionsWatcher)
  yield fork(executeStripeBillingAgreementWatcher)
  yield fork(executePayPalBillingAgreementWatcher)
  yield fork(createPayPalBillingAgreementWatcher)
  yield fork(getSalesProductsWatcher)
  yield fork(getSubscriptionGrantsWatcher)
  yield fork(getPlanGrantsWatcher)
  yield fork(createPlanGrantWatcher)
  yield fork(deletePlanGrantWatcher)
  yield fork(getSubscriptionLicenseKeysWatcher)
  yield fork(getLicenseKeysAttachmentsWatcher)
  yield fork(getSubscriptionInvoicesWatcher)
  yield fork(stripeRefundWatcher)
  yield fork(getSubscriptionCardWatcher)
  yield fork(updateSubscriptionCardWatcher)
  yield fork(changeUrlWatcher)
  yield fork(redirectToSubscriptionWatcher)
  yield fork(transferSubscriptionWatcher)
  yield fork(getAllSubscriptionsWatcher)
  yield fork(getCheckoutSessionWatcher)
  yield fork(editSubscriptionCardWatcher)
  yield fork(retryInvoiceWatcher)
  yield fork(retryGetSubscriptionWatcher)
  yield fork(getSubscriptionDBLogsWatcher)
  yield fork(extendOfflineSubscriptionWatcher)
  yield fork(getExtendEventsWatcher)
  yield fork(getCancelEventsWatcher)
  yield fork(getLicenseKeyAddedEventsWatcher)
}
