import React from 'react'
import { DataType } from 'types/form'
import { omit } from 'ramda'
import config from 'config'
import i18n from 'i18n'
import { getUTCDate } from 'utils/dateTime'
import { wingtraSerialPattern } from 'utils/regex'
import { ieLicenseKeyVersionPattern } from 'utils/patterns'
import { compareVersions } from 'utils/licenseKeys'

export const RejectionOptions = [
  { label: 'Configuration Issue', value: 'configuration', id: 'configuration' },
  { label: 'Hardware Issue', value: 'hardware', id: 'hardware' },
  { label: 'Software Issue', value: 'software', id: 'software' },
  { label: 'Connection Issue', value: 'connection', id: 'connection' },
  { label: 'Instruction unclear', value: 'instruction_unclear', id: 'instruction_unclear' },
  { label: 'Pilot error', value: 'pilot_error', id: 'pilot_error' },
  { label: 'Environmental Issue', value: 'environmental', id: 'environmental' },
]

export const getCommonFields = (options = {}) => {
  const { omit: omitFields = [], merge = {} } = options
  const { timestamp = {}, description = {}, attachments = {} } = merge
  const fields = {
    timestamp: {
      name: i18n.t('event.name.timestamp'),
      dataType: DataType.DATE,
      initialValue: (state, extra, { extraProps = {} }) => {
        const { event } = extraProps
        return event ? getUTCDate(event.timestamp) : getUTCDate()
      },
      backendTransform: (original, state, formValues) => original ? original.format() : '',
      isChanged: (original, event) => {
        return getUTCDate(event.timestamp).format(config.DATE_FORMAT) !==
        getUTCDate(original).format(config.DATE_FORMAT)
      },
      optionProps: {
        keyboard: true,
        inputVariant: 'outlined',
        format: config.DATE_FORMAT,
        placeholder: 'YYYY-MM-DD',
      },
      ...timestamp,
    },
    description: {
      name: i18n.t('event.name.description'),
      dataType: DataType.STRING,
      initialValue: (state, extra, { extraProps = {} }) => {
        const { event } = extraProps
        return event ? event.description : ''
      },
      variant: 'outlined',
      multiline: true,
      displayValue: value => value ? <pre style={{ margin: 0, fontFamily: 'inherit', textWrap: 'wrap' }}>{value}</pre> : null,
      ...description,
    },
    attachments: {
      name: i18n.t('event.name.attachments'),
      dataType: DataType.UPLOAD_FILES,
      initialValue: (state, extra, { extraProps = {} }) => {
        const { event } = extraProps
        return (event ? event.attachments : [])
          .map(att => {
            return {
              ...att,
              isImage: att.isImage && att.document_type !== DocumentType.THUMBNAIL,
            }
          })
      },
      isEditable: true,
      isChanged: (value, event) => {
        const newAttachmentsIds = value.map(f => f.id)
        const oldAttachmentsIds = event.attachments.map(f => f.id)
        return !newAttachmentsIds.every(id => oldAttachmentsIds.includes(id))
      },
      backendTransform: (value, state, rawValues) => value.filter(v => !v.uploaded),
      displayValue: null,
      ...attachments,
    },
  }
  return omit(omitFields, fields)
}

export function isEvaluationDaysSet (evaluation_days) {
  if (evaluation_days === '') {
    return false
  }
  const evaluationDays = +evaluation_days
  return (
    typeof evaluationDays === 'number' &&
    !isNaN(evaluationDays)
  )
}

export const onTransform = cb => (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData, extraProps) => {
  // eslint-disable-next-line standard/no-callback-literal
  return cb({
    original,
    state,
    formValues,
    extra,
    formTemplate,
    name,
    oldValues,
    analyzeData,
    extraProps: extraProps || {},
  })
}

/**
 * Validates a rover serial number.
 * @param {string} value - The rover serial number to validate. Can be 3 different serial numbers: PlsSerial (ex. 12345), ReconSerial (ex. R12345), and Wingtra (ex. WINGTRA-a12345)
 * @returns {{ okay: boolean, message?: string }} An object indicating whether the serial number is valid.
 * The `okay` property indicates whether the serial number is valid or not.
 * If `okay` is false, an optional `message` property provides a descriptive error message.
 */
export const validateRoverSerial = value => {
  const nValue = +value

  const isPlsSerial = Number.isInteger(nValue)
  const isReconSerial = value.toLowerCase().startsWith('r')
  const isWingtraSerial = value.startsWith('WINGTRA')

  if (isPlsSerial) {
    if (nValue < 22000) {
      return { okay: false, message: 'PLS serial should be at least 22000' }
    }
    if (value.length >= 6) {
      return { okay: false, message: 'Only 5 digit PLS serial numbers allowed' }
    }
  } else if (isWingtraSerial) {
    if (!wingtraSerialPattern.test(value)) {
      return { okay: false, message: 'WINGTRA serial format: WINGTRA- + 6 alphanumeric characters' }
    }
  } else if (isReconSerial) {
    return { okay: true }
  } else return { okay: false, message: 'Invalid format' }
  return { okay: true }
}

/**
 * Validates the format of a license key version.
 * The version must consist of two, or three numeric groups separated by dots (e.g., "10.10", "10.10.10").
 * Each group must contain only digits (0-9).
 */
export const validateLicenseKeyVersion = value => {
  if (typeof value !== 'string' || !value.trim()) {
    return { okay: false, message: i18n.t('controlPanel.user.subscription.validation.empty') }
  }

  if (!ieLicenseKeyVersionPattern.test(value)) {
    return {
      okay: false,
      message: i18n.t('controlPanel.user.subscription.validation.invalid'),
    }
  }

  return { okay: true }
}

/**
 * Filters license keys based on whether they have version information.
 *
 * - If no license key has a `supported_version`, return the newest license key based on `created` field.
 * - If versions exist, return the key with the highest `supported_version`.
 * - Handles cases where `supported_version` is `null` or missing.
 *
 * @param {Array} licenseKeys - An array of license key objects.
 * @returns {Array} An array containing a single license key (highest version or newest if no versions exist).
 */
export const filterLicenseKeys = licenseKeys => {
  const hasVersionKeys = licenseKeys.some(key => key.supported_version)
  if (!hasVersionKeys) {
    return licenseKeys.slice(0, 1)
  }

  // Sort by `supported_version`, handling null values
  const sortedLks = [...licenseKeys].sort((a, b) => {
    if (!a.supported_version) return 1
    if (!b.supported_version) return -1
    return compareVersions(b.supported_version, a.supported_version)
  })
  return sortedLks.slice(0, 1)
}
