import { call, put, takeLatest, takeEvery, fork, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import i18next from 'i18n'
import history from 'config/history'
import axios from 'utils/axios'
import { routeUsers, routeCompanyUserRegisterSuccess, routeError } from 'utils/routing'
import { createQueryString, getErrorMessage, getPaginationFromResponse, showErrorMessage } from 'utils/api'
import { register } from '../utils'
// Local deps
import CompaniesActions, { CompaniesTypes } from './actions'
import UsersActions from 'modules/users/actions'
import { getAddress } from 'utils/users'
import { isGetCompanySubscriptionsLoading } from './selectors'
import config from 'config'
import { SubscriptionFiltersTemplate } from 'templates/SubscriptionFilterTemplate'

// Sagas
// Retrieve a list of companies (used by admins)
function * getCompanies () {
  yield put(CompaniesActions.getCompaniesLoading())
  try {
    const { data: { data: companies } } = yield call(axios.get, `/companies`)
    yield put(CompaniesActions.getCompaniesSuccess(companies))
  } catch (e) {
    yield put(CompaniesActions.getCompaniesFailure(getErrorMessage(e)))
  }
}
function * getChildCompanies ({ companyId, failureCallback }) {
  yield put(CompaniesActions.getChildCompaniesLoading(companyId))
  try {
    const { data: { data: companies } } = yield call(axios.get, `/companies/${companyId}/children`)
    if (companies.length === 0 && failureCallback) {
      failureCallback()
    }
    yield put(CompaniesActions.getChildCompaniesSuccess(companyId, companies))
  } catch (e) {
    yield put(CompaniesActions.getChildCompaniesFailure(companyId, getErrorMessage(e)))
  }
}
// Retrieve a list of company users
function * getCompanyUsers ({ companyId }) {
  yield put(CompaniesActions.getCompanyUsersLoading())
  try {
    const { data: { data: users } } = yield call(axios.get, `/companies/${companyId}/users`)
    yield put(CompaniesActions.getCompanyUsersSuccess(users))
  } catch (e) {
    yield put(CompaniesActions.getCompanyUsersFailure(getErrorMessage(e)))
  }
}

// Retrieves company for the currently logged user
function * getLoggedUserCompany ({ companyId }) {
  yield put(CompaniesActions.getLoggedUserCompanyLoading())
  try {
    const { data: { data: company } } = yield call(axios.get, `/companies/${companyId}`)
    yield put(CompaniesActions.getLoggedUserCompanySuccess(company))
  } catch (e) {
    const status = e.response.status
    if (status >= 400 && status < 500) {
      history.push(routeError())
      if (status === 403) {
        toast.error('You are not allowed to see this page')
      } else {
        toast.error('Error while getting active company')
      }
    }
    yield put(CompaniesActions.getLoggedUserCompanyFailure(getErrorMessage(e)))
  }
}

// Retrieve individual company
function * getCompany ({ companyId }) {
  yield put(CompaniesActions.getCompanyLoading())
  try {
    const { data: { data: company } } = yield call(axios.get, `/companies/${companyId}`)
    yield put(CompaniesActions.getCompanySuccess(company))
  } catch (e) {
    const status = e.response.status
    if (status >= 400 && status < 500) {
      history.push(routeError())
      if (status === 403) {
        toast.error('You are not allowed to see this page')
      } else {
        toast.error('Error while getting active company')
      }
    }
    yield put(CompaniesActions.getCompanyFailure(getErrorMessage(e)))
  }
}
// Generate invite url for a company with provided email and company role (customer or employee)
function * generateInviteUrl ({ companyId, email, companyRole }) {
  yield put(CompaniesActions.generateInviteUrlLoading(companyId))
  try {
    const { data: { message } } = yield call(axios.post, `/companies/${companyId}/invite_user`, {
      company_role: companyRole,
      email,
    })
    toast.success(message)
    yield put(CompaniesActions.generateInviteUrlSuccess(companyId))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.generateInviteUrlFailure(companyId, getErrorMessage(e)))
  }
}
// Register company employee
function * registerEmployee ({ companyId, token, data }) {
  yield put(CompaniesActions.registerEmployeeLoading())
  try {
    const body = {
      email: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      country: data.country,
      address: getAddress(data),
      // phone: data.phone,
      // mapping_system_product: data.mappingSystemProduct,
      // navigation_system_vendor: data.navigationSystemVendor,
      time_zone: data.timeZone,
      terms_accepted: data.termsAccepted,
      // system_serial_numbers: getSystemSerialNumber(data),
    }
    yield call(register, `/companies/${companyId}/register/${token}`, body, true, routeCompanyUserRegisterSuccess())
    yield put(CompaniesActions.registerEmployeeSuccess())
  } catch (e) {
    yield put(CompaniesActions.registerEmployeeFailure(getErrorMessage(e)))
  }
}
// Admin register company employee
function * adminRegisterEmployee ({ companyId, data, onSuccess }) {
  yield put(CompaniesActions.adminRegisterEmployeeLoading())
  try {
    const { is_active, is_crs_advanced } = data
    const body = {
      email: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      country: data.country,
      address: getAddress(data),
      terms_accepted: data.termsAccepted,
      // system_serial_numbers: getSystemSerialNumber(data),
      send_set_password_email: data.send_set_password_email,
      ...('website_access' in data && { website_access: data.website_access }),
      ...('fp_access' in data && { fp_access: data.fp_access }),
      ...('fp_end_date' in data && { fp_end_date: data.fp_end_date }),
    }
    const responseData = yield call(register, `/companies/${companyId}/users`, body, false, undefined, true)
    const user = responseData.data
    // Update is_active and/or is_crs_advanced after user created
    if (is_active || is_crs_advanced) {
      yield put(UsersActions.updateUser({
        id: user.id,
        ...(is_crs_advanced && { is_crs_advanced }),
        ...(is_active && { is_active }),
      }, false))
    }
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    yield put(CompaniesActions.adminRegisterEmployeeSuccess(companyId, user))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.adminRegisterEmployeeFailure(getErrorMessage(e)))
  }
}
// Update individual company data (name, phone, ...etc)
function * updateCompany ({ companyId, data, withSuccess = true }) {
  yield put(CompaniesActions.updateCompanyLoading())
  try {
    const { data: { data: company } } = yield call(axios.put, `/companies/${companyId}`, data)
    if (withSuccess) {
      toast.success(i18next.t('toast.company.updateSuccess'))
    }
    yield put(CompaniesActions.updateCompanySuccess(company))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.updateCompanyFailure(getErrorMessage(e)))
  }
}
// Update individual company addresses
function * updateCompanyAddresses ({ companyId, companyAddresses, newCompanyAddresses }) {
  yield put(CompaniesActions.updateCompanyAddressesLoading())
  try {
    const postalAddressId = companyAddresses.find(address => address.address_type === 'postal')
    const physicalAddressId = companyAddresses.find(address => address.address_type === 'physical')
    const { data: { data: postalAddress } } = yield call(axios.patch, `/addresses/${postalAddressId.id}`, newCompanyAddresses['postal'])
    const { data: { data: physicalAddress } } = yield call(axios.patch, `/addresses/${physicalAddressId.id}`, newCompanyAddresses['physical'])
    toast.success('Addresses were successfully updated')
    yield put(CompaniesActions.updateCompanyAddressesSuccess(companyId, [postalAddress, physicalAddress]))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.updateCompanyAddressesFailure(getErrorMessage(e)))
  }
}
// Create addresses for individual company
function * createCompanyAddresses ({ companyId, companyAddresses }) {
  yield put(CompaniesActions.updateCompanyAddressesLoading())
  try {
    // Add company addresses after user created
    const { data: { data: postalAddress } } = yield call(axios.post, `/companies/${companyId}/addresses`, {
      address_type: 'postal',
      ...companyAddresses['postal'],
    })
    const { data: { data: physicalAddress } } = yield call(axios.post, `/companies/${companyId}/addresses`, {
      address_type: 'physical',
      ...companyAddresses['physical'],
    })
    toast.success('Addresses were successfully updated')
    yield put(CompaniesActions.updateCompanyAddressesSuccess(companyId, [postalAddress, physicalAddress]))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.updateCompanyAddressesFailure(getErrorMessage(e)))
  }
}
// Delete individual company
function * deleteCompany ({ companyId }) {
  yield put(CompaniesActions.deleteCompanyLoading())
  try {
    yield call(axios.delete, `/companies/${companyId}`)
    history.push(routeUsers())
    yield put(CompaniesActions.deleteCompanySuccess(companyId))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.deleteCompanyFailure(getErrorMessage(e)))
  }
}
// Retrieve a list of company system types
function * getCompanySystemTypes ({ companyId }) {
  yield put(CompaniesActions.getCompanySystemTypesLoading(companyId))
  try {
    const { data: { data: systemTypes } } = yield call(axios.get, `/companies/${companyId}/system_types`)
    yield put(CompaniesActions.getCompanySystemTypesSuccess(companyId, systemTypes))
  } catch (e) {
    yield put(CompaniesActions.getCompanySystemTypesFailure(companyId, getErrorMessage(e)))
  }
}
// Add system type for a company
function * addCompanySystemType ({ companyId, systemId, systemType }) {
  yield put(CompaniesActions.addCompanySystemTypeLoading())
  try {
    yield call(axios.post, `/companies/${companyId}/system_types/${systemId}`, {})
    yield put(CompaniesActions.addCompanySystemTypeSuccess(companyId, systemId, systemType))
    toast.success(i18next.t('toast.company.addCompanySystemTypeSuccess'))
  } catch (e) {
    yield put(CompaniesActions.addCompanySystemTypeFailure(getErrorMessage(e)))
  }
}
// Delete individual system type for a company
function * deleteCompanySystemType ({ companyId, systemId }) {
  yield put(CompaniesActions.deleteCompanySystemTypeLoading())
  try {
    yield call(axios.delete, `/companies/${companyId}/system_types/${systemId}`)
    yield put(CompaniesActions.deleteCompanySystemTypeSuccess(companyId, systemId))
    toast.success(i18next.t('toast.company.deleteCompanySystemTypeSuccess'))
  } catch (e) {
    yield put(CompaniesActions.deleteCompanySystemTypeFailure(getErrorMessage(e)))
  }
}
// Retrieve a list of company subscriptions
function * getCompanySubscriptions ({ companyId, withLoading = true }) {
  const isLoading = yield select(state => isGetCompanySubscriptionsLoading(state, companyId))
  // Load only once per company
  if (!isLoading) {
    if (withLoading) {
      yield put(CompaniesActions.getCompanySubscriptionsLoading(companyId))
    }
    try {
      const { data: { data: subscriptions } } = yield call(axios.get, `/companies/${companyId}/subscriptions`)
      yield put(CompaniesActions.getCompanySubscriptionsSuccess(companyId, subscriptions))
    } catch (e) {
      yield put(CompaniesActions.getCompanySubscriptionsFailure(companyId))
    }
  }
}
export function getQueryString (field, values = []) {
  if (!Array.isArray(values)) {
    return `${field}=${values}`
  }
  return values.length > 0 ? `${field}=${values.join(',')}` : ''
}
// Retrieve a list of company subscriptions filtered by the given filters
function * getCompanySubscriptionsByFilters ({ companyId, filters, withLoading = true }) {
  if (withLoading) {
    yield put(CompaniesActions.getCompanySubscriptionsByFiltersLoading(companyId, filters))
  }
  try {
    const queryString = Object
      .keys(filters)
      .map(key => {
        // discard filter entirely
        if (key === 'is_archived' && filters[key] === 'all') {
          return
        }
        const toBackend = SubscriptionFiltersTemplate[key].toBackend || function (item) { return item }
        const value = toBackend(filters[key])
        return getQueryString(key, value)
      })
      .filter(Boolean)
      .join('&')
    const { data: { data: subscriptions } } = yield call(axios.get, `/companies/${companyId}/subscriptions?${queryString}`)
    yield put(CompaniesActions.getCompanySubscriptionsByFiltersSuccess(companyId, subscriptions, filters))
  } catch (e) {
    yield put(CompaniesActions.getCompanySubscriptionsByFiltersFailure(companyId))
  }
}
// Check invite token for exparation
function * checkInviteToken ({ token }) {
  yield put(CompaniesActions.checkInviteTokenLoading(token))
  try {
    yield call(axios.get, `/companies/check_invite/${token}`)
    yield put(CompaniesActions.checkInviteTokenSuccess(token))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.checkInviteTokenFailure(token, getErrorMessage(e)))
  }
}
// Get all company rovers
function * getCompanyRovers ({ companyId }) {
  yield put(CompaniesActions.getCompanyRoversLoading(companyId))
  try {
    const { data: { data: rovers } } = yield call(axios.get, `/companies/${companyId}/rovers`)
    yield put(CompaniesActions.getCompanyRoversSuccess(companyId, rovers))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.getCompanyRoversFailure(companyId, getErrorMessage(e)))
  }
}
// Get all company rovers
function * getCompanyProjects ({ companyId }) {
  yield put(CompaniesActions.getCompanyProjectsLoading(companyId))
  try {
    const { data: { data: projects } } = yield call(axios.get, `/companies/${companyId}/projects`, { baseURL: config.LIDARMILL_API_BASE })
    yield put(CompaniesActions.getCompanyProjectsSuccess(companyId, projects))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.getCompanyProjectsFailure(companyId, getErrorMessage(e)))
  }
}

// Get all company wide max versions
function * getCompanyWideMaxVersion ({ companyId }) {
  yield put(CompaniesActions.getCompanyWideMaxVersionLoading(companyId))
  try {
    const { data: { data: companyWideMaxVersions } } = yield call(axios.get, `/companies/${companyId}/licensing_product_max_versions`)
    yield put(CompaniesActions.getCompanyWideMaxVersionSuccess(companyId, companyWideMaxVersions))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.getCompanyWideMaxVersionFailure(companyId, getErrorMessage(e)))
  }
}

// Get company db logs
function * getCompanyDBLogs ({ companyId, pagination, onSuccess }) {
  const { search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc' } = pagination
  yield put(CompaniesActions.getCompanyDBLogsLoading())
  try {
    const { data: { data: logs, pagination } } = yield call(axios.get, `/companies/${companyId}/db_logs${createQueryString({ search, pageSize, page, order, orderBy })}`)
    yield put(CompaniesActions.getCompanyDBLogsSuccess(companyId, logs, getPaginationFromResponse(pagination.total, page, pageSize)))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.getCompanyDBLogsFailure(e.message || e))
  }
}

function * getCompanyProductUpdates ({ companyId, product }) {
  const productName = product.name.toLowerCase()
  yield put(CompaniesActions.getCompanyProductUpdatesLoading(companyId, productName))
  try {
    const { data: { data: updates } } = yield call(axios.get, `/companies/${companyId}/products/${product.name}/updates`)
    yield put(CompaniesActions.getCompanyProductUpdatesSuccess(companyId, productName, updates))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.getCompanyProductUpdatesFailure(companyId, productName, e.message || e))
  }
}

// Watchers
function * getCompaniesWatcher () {
  yield takeLatest(CompaniesTypes.GET_COMPANIES, getCompanies)
}
function * getChildCompaniesWatcher () {
  yield takeEvery(CompaniesTypes.GET_CHILD_COMPANIES, getChildCompanies)
}
function * getCompanyUsersWatcher () {
  yield takeLatest(CompaniesTypes.GET_COMPANY_USERS, getCompanyUsers)
}
function * updateCompanyWatcher () {
  yield takeLatest(CompaniesTypes.UPDATE_COMPANY, updateCompany)
}
function * updateCompanyAddressesWatcher () {
  yield takeLatest(CompaniesTypes.UPDATE_COMPANY_ADDRESSES, updateCompanyAddresses)
}
function * createCompanyAddressesWatcher () {
  yield takeLatest(CompaniesTypes.CREATE_COMPANY_ADDRESSES, createCompanyAddresses)
}
function * getCompanyWatcher () {
  yield takeLatest(CompaniesTypes.GET_COMPANY, getCompany)
}
function * getLoggedUserCompanyWatcher () {
  yield takeLatest(CompaniesTypes.GET_LOGGED_USER_COMPANY, getLoggedUserCompany)
}
function * generateInviteUrlWatcher () {
  yield takeLatest(CompaniesTypes.GENERATE_INVITE_URL, generateInviteUrl)
}
function * registerEmployeeWatcher () {
  yield takeLatest(CompaniesTypes.REGISTER_EMPLOYEE, registerEmployee)
}
function * adminRegisterEmployeeWatcher () {
  yield takeLatest(CompaniesTypes.ADMIN_REGISTER_EMPLOYEE, adminRegisterEmployee)
}
function * deleteCompanyWatcher () {
  yield takeLatest(CompaniesTypes.DELETE_COMPANY, deleteCompany)
}
function * getCompanySystemTypesWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_SYSTEM_TYPES, getCompanySystemTypes)
}
function * addCompanySystemTypeWatcher () {
  yield takeLatest(CompaniesTypes.ADD_COMPANY_SYSTEM_TYPE, addCompanySystemType)
}
function * deleteCompanySystemTypeWatcher () {
  yield takeLatest(CompaniesTypes.DELETE_COMPANY_SYSTEM_TYPE, deleteCompanySystemType)
}
function * getCompanySubscriptionsWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS, getCompanySubscriptions)
}
function * getCompanySubscriptionsByFiltersWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_BY_FILTERS, getCompanySubscriptionsByFilters)
}
function * checkInviteTokenWatcher () {
  yield takeLatest(CompaniesTypes.CHECK_INVITE_TOKEN, checkInviteToken)
}
function * getCompanyRoversWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_ROVERS, getCompanyRovers)
}
function * getCompanyProjectsWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_PROJECTS, getCompanyProjects)
}
function * getCompanyWideMaxVersionWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_WIDE_MAX_VERSION, getCompanyWideMaxVersion)
}
function * getCompanyDBLogsWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_DB_LOGS, getCompanyDBLogs)
}
function * getCompanyProductUpdatesWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_PRODUCT_UPDATES, getCompanyProductUpdates)
}

export default function * root () {
  yield fork(addCompanySystemTypeWatcher)
  yield fork(deleteCompanySystemTypeWatcher)
  yield fork(getCompanySystemTypesWatcher)
  yield fork(getCompanySubscriptionsWatcher)
  yield fork(getCompanySubscriptionsByFiltersWatcher)
  yield fork(getCompaniesWatcher)
  yield fork(getChildCompaniesWatcher)
  yield fork(getCompanyUsersWatcher)
  yield fork(updateCompanyWatcher)
  yield fork(updateCompanyAddressesWatcher)
  yield fork(createCompanyAddressesWatcher)
  yield fork(deleteCompanyWatcher)
  yield fork(getCompanyWatcher)
  yield fork(getLoggedUserCompanyWatcher)
  yield fork(generateInviteUrlWatcher)
  yield fork(registerEmployeeWatcher)
  yield fork(adminRegisterEmployeeWatcher)
  yield fork(checkInviteTokenWatcher)
  yield fork(getCompanyRoversWatcher)
  yield fork(getCompanyProjectsWatcher)
  yield fork(getCompanyWideMaxVersionWatcher)
  yield fork(getCompanyDBLogsWatcher)
  yield fork(getCompanyProductUpdatesWatcher)
}
